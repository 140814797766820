@import "../../assets/scss/variable";

.toggle {
    display: inline-flex;
    &--left,
    &--right {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px 30px;
        width: 180px;
        height: 51px;
        background: $white;
        border: 1px solid $gray-neutral;
        border-radius: 200px 0px 0px 200px;
        cursor: pointer;
        color: $gray-neutral-7;
        font-weight: 700;
    }

    &--right {
        border-radius: 0px 200px 200px 0px;
    }

    .active {
        background: $blue-light-10;
        border: 1px solid $blue;
        color: $blue
    }
}