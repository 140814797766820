@import "../../../assets/scss/variable";

.accordion-item {
    list-style-type: none;
    cursor: pointer;
    display: flex; 
    flex-direction: column; 

    .item-select-default {
        display: flex;
        align-items: center;
        cursor: pointer;

        .default-title {
            color: $blue;
            font-size: 16px;
            font-weight: 400;
            margin-right: 0.5rem;
        }

        .default-caret {
            color: $blue;
            cursor: pointer;
        }
    }

    .item-select-lg {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        padding: 1.5rem 1rem;

        .lg-title {
            color: $black-2;
            font-size: 18px;
            font-weight: 400;
            width: calc(100% - 5rem);
        }

        .lg-caret {
            background: $blue-light-10;
            border-radius: 200px;
            display: inline-flex;
            height: 32px;
            margin-left: 0.5rem;
            width: 32px;
            justify-content: center;
            align-items: center;

            @media (max-width: $bp-detail-page-small) {
                margin-right: 1rem;
            }

            svg {
                color: $blue;

                polyline {
                    stroke-width: 30;
                }
            }
        }
    }
}