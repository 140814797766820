@import "../../assets/scss/variable";

.upload-file-container {
    width:calc(100% - 1rem);
    height:54px;
    border-radius: 4px;
    gap: 10px;
    box-sizing: border-box;
    background-color: $white;
    border: 1px solid $gray-neutral-4;
    display:flex;
    align-items: center;

    img {
        width:20px;
        height:20px;
    }
    .inputfile{
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    
        ::after, ::before{
            box-sizing: border-box;
        }
    }
    
    .inputfile + label {
        font-size: 16px;
        font-weight:400;
        color:$black-2;
        align-items: center;
        display: flex;
        font-style: italic;
        position:absolute;
        right: calc(10% - 2rem);
        gap:0px;
        margin-bottom: 0rem;
    }
    label {
        right: 10px;
        position: absolute;
        border: 1px solid $gray-neutral-4;
        box-sizing: border-box;
        background-color: $gray-neutral;
        padding: 4px 6px;
        border-radius: 4px;
        font-style: italic;
    }
    .upload-filename{
        margin-left:10px;
        color:$black;
        font-style: italic;

        p{
            margin-bottom: 0rem;
        }
    }
}
.upload-progress-bar{
    display: flex;
    align-items: center;
    gap: 8px;

    .progress-bar-file{
        height: 8px;
        background-color: $blue;
        margin-right: 1rem;
        border-radius: 100px;
    }
    .check-image{
        width: 36px;
        height: 25px;
        justify-content: center;
        display: flex;
        align-items: center;
    }
}

