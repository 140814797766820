@import "../../assets/scss/variable";


.recommended-item-manage {
    box-shadow: 0px 2px 20px rgba(10, 56, 217, 0.1);
    display: flex;
    justify-content: space-between;
    justify-items: center;
    align-items: center;
    margin: 0 0 1rem 0;
    cursor: pointer;
    background: $white;
    border-radius: 8px;
    border: 1px solid $gray-neutral;
    box-sizing: border-box;
    padding: 15px;
    font-weight: 400;
    font-size: 16px;
    color: $gray-neutral-7;
   
    @media all and (max-width: $bp-sidebar-hamburger) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .recommended-date-manage {   
        position: absolute;
        right: 600px;
        margin-right: -170px;

        &.disabled {
            right: 559px;
        }

        @media all and (max-width: $bp-sidebar-hamburger) {
            margin-top: 1rem;
        }
    }

    .icons {
        justify-content:center;
        position: relative;
        display: flex;
        align-items: center;
        .trash, .drag, .edit {
            padding-left: 40px;
        }

        .switch-container {
            padding-left: 15px;
        }

        p {
            color: $black-2;
            font-size: 16px;
            font-weight: 400;
            padding-left: 40px;
        }
        
    }

    &.orange {
        border-left: 8px solid $orange-light-30;

        &:hover {
            box-shadow:
            0px 2px 20px rgba(10, 56, 217, 0.1),
            0 0 0 1px $orange;
        }
    }

    &.blue {
        border-left: 8px solid $blue-light-30;

        &:hover {
            box-shadow:
            0px 2px 20px rgba(10, 56, 217, 0.1),
            0 0 0 1px $blue-light;
        }
    }

    &.purple {
        border-left: 8px solid $purple-30;

        &:hover {
            box-shadow:
            0px 2px 20px rgba(10, 56, 217, 0.1),
            0 0 0 1px $purple;
        }
    }

    &.green {
        border-left: 8px solid $green-30;

        &:hover {
            box-shadow:
            0px 2px 20px rgba(10, 56, 217, 0.1),
            0 0 0 1px $green-1;
        }

    }

    &.disabled {
        filter: brightness(90%);
    }

    .recommended-content-manage {
        display: inline-flex;
        align-items: center;

        input {
            margin-right: 6px;
            height: 15px;
            width: 15px;
            display: none;
        }

        img {
            height: 40px;
            width: 40px;
        }

        p {
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            overflow-wrap: anywhere;
            color: $black-2;

            a {
                font-weight: 500;
                text-decoration: none;
                border-bottom: 1px solid $blue;
                padding-bottom: 0.1rem;
                color: $black;

                &:active {
                    color: unset;
                }
            }
        }
    }

    &:hover {
        .recommended-content-manage {
            input{
                display: inline-flex;
            }    
        }
    }



    &.orange {
        &.checked {
            background-color: $orange-light-30;
            input{
                display: inline-flex;
            }
        }
    }

    &.blue {
        &.checked {
            background-color: $blue-light-30;
            input{
                display: inline-flex;
            }
        }
        
    }

    &.purple {
        &.checked {
            background-color: $purple-30;
            input{
                display: inline-flex;
            }
        }
        
    }

    &.green {
        &.checked {
            background-color: $green-30;
            input{
                display: inline-flex;
            }
        }
        

    }

    @media all and (max-width: $bp-sidebar-top) {
        .recommended-content-manage {
            input{
                display: inline-flex;
                position: absolute;
                left: 0rem;
                margin: 1rem;
                margin-top:2rem;
            }
        }    
        
        
    }
}

@media all and (max-width: $bp-sidebar-top) {
    .recommended-item-manage {
        margin-left: 1rem !important;
        height: 110px;
        .recommended-content-manage {
            padding-bottom: 20px;
        }
        .recommended-date-manage {
            position: absolute;
            left: 65px;
            margin-top: 60px;
        }
    }
}
