@import "../../assets/scss/variable";
@import "animate.css";

@keyframes term-cell-animate {
    0% {background-color: $white}
    65% {background: $blue-light-25;
        border: 1px solid $green-1;
        border-radius: 8px;
        }
    100% {background-color: $white;}
  }

@keyframes  grouped-terms-animate{
    0% {background: $white}
    65% {background: $blue-light-25}
    100% {background: $white}
}
.term-detail--container{
    .bar-blue {
        @media (max-width: $bp-sidebar-hamburger) {
            top: 375px;
        }
    }

    .back-button {
        @media all and (max-width: $bp-sidebar-top) {
            margin-top: 1rem;
        }
    }
    
    background: linear-gradient(180deg, $white 21.04%, $gray-neutral-1 43.32%);
    height: 100vh;

    .data-container {
        padding-bottom: 1px;
        background: $green-5;
    }

    .loading-spinner {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-top: 8rem;
    }

    .term-detail-page-container{
        
        .back-button--glossary{
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
        }
        .title{
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            color: $black-2;
            left: 11rem;
            position: relative;
            @media all and (max-width: $bp-sidebar-small) and (min-width: calc($bp-sidebar-top + 1px)) {
                left: 9rem;
            }

            @media all and (max-width: $bp-sidebar-top) {
                left: 1.5rem;
            }
        }

        .bar-grey{
            height: 1px;
            background: $gray-neutral-4;
            margin-left: 11rem;
            margin-top: 1.5rem;
            margin-right: 1.5rem;
            @media all and (max-width: $bp-sidebar-small) and (min-width: calc($bp-sidebar-top + 1px)) {
                margin-left: 9rem;
            }

            @media all and (max-width: $bp-sidebar-top) {
                margin-left: 1.5rem;
            }
        }
        .parent-term--container{
            display: flex;
            flex-direction:column;
            align-items: flex-start;
            padding: 0px;
            gap: 24px;
            width: 100%;

            .results-datatable{
                width: 100%;
            }

            table{
                tr{
                    min-width: calc(100% - 10rem);
                }
            }

            .synonym-items table tr{
                background: $white;
                border: unset;
            }
        }

        .child-term--container{
            margin-top: 1.5rem;
            background: $green-5;

            .title{
                padding-bottom: 1.5rem;
                color: $black-2;
            }
        }

        .datatable {
            padding-right: 1.5rem;
    
            table tbody:before {
                line-height: 0;
            }
    
            thead {
                display: none;
            }
    
            tr {
                justify-content: space-between;
                height: auto;
                position: relative;
                margin-left: 1rem;
                box-shadow: 0px 2px 20px rgba(10, 56, 217, 0.1);
    
                td {
                    &:first-child {
                        align-items: flex-start;
                        width: 100%;

                        .term-cell, .term-cell-clicked{
                            align-items: flex-start;
                            display: flex;
                            flex-direction: column;
                            width: 100%;

                            
                            .title {
                                padding: 1rem 0 0 1rem;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 18px;
                                line-height: 21px;
                                color: $black-2;
                                left: unset;
                            }
        
                            .definition {
                                padding: 0 1rem 0 1rem;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 18px;
                                line-height: 180%;
                                color: $gray-neutral-7;
                                margin: 0.5rem 7rem 0.5rem 0;
        
                                @media all and (max-width:$bp-sidebar-hamburger){
                                    margin-right:0;
                                }
        
                                p {
                                    padding-top: 1rem;
        
                                    &:first-child {
                                        padding-top: 0;
                                    }
                                    & > img {
                                        display: none;
                                    }
                                }
                                
        
                                ul {
                                    padding-left: 1.5rem
                                }
                            }
                        }

                        .term-cell-clicked{
                            animation-name: term-cell-animate;
                            animation-duration: 12s;
                            animation-delay: -5s;
                            animation-timing-function: ease-out;

                            .grouped-terms .btn{
                                animation-name: grouped-terms-animate;
                                animation-duration: 12s;
                                animation-delay: -5s;
                                animation-timing-function: ease-out;
                            }
                        }
    
                    }
    
                    flex-direction: column;
    
                    .tags {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                        justify-content: space-between;
                        position: absolute;
                        right: 0;
                        top: 0;
    
                        @media all and (max-width : $bp-sidebar-hamburger){
                            margin-left: 1rem;
                            position: relative;
                            display: block;
                            margin-bottom:0.5rem;
                        }
    
                        .chip {
                            margin-top: 1rem;
                            margin-right: 1rem;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 17px;
                            padding: 4px 12px;
                            border-radius: 200px;
                            color: $white;
    
                            &.chip--purple {
                                background: $blue;
                            }
    
                            &.chip--blue {
                                background: $blue-light;
                            }
    
                            &.chip--green {
                                background: $green;
                            }
    
                            &.chip--yellow {
                                background: $orange;
                            }
    
                            &.chip--grey {
                                background: $gray-light-2;
                            }
                        }
    
                        .type {
                            margin-right: 1rem;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 180%;
                            text-align: right;
                            color: $gray-neutral-7;
                        }
                    }

                    .grouped-terms{
                        display: flex;
                        position: absolute;
                        gap: 4px;
                        align-items: flex-start;
                        padding-right: 8px;
                        right: 0px;
                        
    
                        .btn {
                            font-style: normal;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 10px;
                            display: flex;
                            letter-spacing: 0.02em;
                            color: $gray-neutral-7;
                            gap: 4px;
                            background: $white;

                            svg{
                                width: 16px;
                                height: 16px;
                            }
                        }
                        
                    }
    
                    section.tab {
                        margin-left: 0px;
                        width:100%;
                    }
    
                    section {
                        .tab {
                            &--container{
                                height: 35px;
                                .single-tab--header-container{
                                    @media all and (min-width:calc($bp-sidebar-hamburger + 1px)){
                                        display:none;
                                    } 
                                }
                                .all-tab--header-container{
                                    @media all and (max-width:$bp-sidebar-hamburger){
                                        display:none;
                                    } 
                                }
                            }
                            &--inactive {
                                display: none;
                            }
                            &--holder {
                                margin-top:unset;
                                border-bottom: unset;
    
                                button {
                                    display: inline-flex;
                                    align-items: center;
    
                                    .default-caret {
                                        padding-bottom: unset;
                                        padding-top: 0.3rem;
                                    }
                                }
                            }
    
                            &--panel {
    
                                .dashboard-items {
                                    overflow: auto;
                                    max-height: 340px;
                                    display: flex;
                                    flex-wrap: wrap;
                                    background: $green-5;
                                    padding-left: 1rem;
                                    padding-bottom: 1rem;
                                    padding-top: 1rem;
                                    min-width: unset;
            
                                    .product-tile {
                                        margin-right: 1rem;
                                        flex: 1 0 21%;
                                        margin-left:1px;
                                        margin-top:2px;
                                    }
    
                                    @media all and (min-width: 1281px) {
                                        display: grid;
                                        grid-template-columns: repeat(3, minmax(300px, 535px));
                                    }
            
                                    @media all and (max-width: 1280px) and (min-width:calc($bp-sidebar-hamburger)) {
                                        display: grid;
                                        grid-template-columns: repeat(2, minmax(300px, 535px));
                                    }
                                }
    
                                .synonym-items {
                                    padding: 8px 24px;
                                    overflow: auto;
                                    max-height: 340px;
                                    display: flex;
                                    flex-wrap: wrap;
                                    background: $green-5;
    
                                    table{
                                        margin-left: 0rem;
                                        min-width:100%;
                                    }
                                    @media all and (max-width: $bp-sidebar-hamburger){
                                        display: none;
                                    }
                                }
                            }
    
                            &--panel-container {
                                height: auto;
                                &.inactive{
                                    display: none;
                                }
                            }
                        }            
                    }
                }
            }
        }
    }

}