@import "../../assets/scss/variable";

.description {
    color: $gray-light-2;
    font-size: 1rem;
    font-weight: normal;
    line-height: 28px;
    margin: 0rem 2rem 1.5rem 2rem;

    word-break: break-word;
    overflow: hidden;
    line-height: 28px;
    height: 84px;

    &.show-more {
        height: auto;
        word-break: unset;
        -webkit-line-clamp: unset;
        -webkit-box-orient: unset;
        display: unset;
        margin-bottom: 3rem;

        p {

            & > img {
                display: none;
            }
        }
    }

    &-container {
        display: inline-flex;
        flex-direction: column;
        position: relative;

        .read-button {
            cursor: pointer;
            margin: 0 2rem 1.8rem 0;
            position: absolute;
            bottom: 0;
            right: 0;
            background-color: $white;

            .continue-reading {
                border-bottom: 1px solid $blue;
            }

            &.read-less {
                border-bottom: 1px solid $blue;
            }

            .ellipsis-overflow {
                color: $gray-light-2;
                font-size: 1rem;
            }
        }
    }

    ul {
        padding-left: 1.5rem
    }
}