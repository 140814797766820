@import "../../assets/scss/variable";

.form-ds-model{
    display: inline-grid;
    margin: 4.5rem 1.5rem 1.5rem 2.5rem;

    .status-bar{
        padding: 24px;
        align-self: stretch;
        border-radius: 8px;
        box-shadow: 0px 2px 20px 0px rgba(48, 99, 197, 0.10);
        width: calc(100% - 5rem);
        margin-bottom: 24px;

        &.Failed{
            border: 1px solid var(--primary-error, $red-1);
            background: var(--10-red, #F8E7E9);
        }

        &.Success{
            border: 1px solid var(--primary-success, #006D3E);
            background: var(--10-green, #E6F0EC);
        }

        &.Validation{
            border: 1px solid var(--primary-sea, $green-1);
            background: var(--10-sea, $green-10);
        }

        .status-header{
            display: flex;

            img{
                height: 20px;
                width: 20px;
            }
            span{
                color: var(--primary-03, $gray-neutral-7);
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                letter-spacing: 0.24px;
                text-transform: uppercase;
            }
        }

        .status-content{
            
                .status-update{
                    color: var(--primary-02, $black-2);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 180%;
                }
    
                .status-info{
                    color: var(--primary-03, $gray-neutral-7);
                    font-size: 16px;
                    font-weight: 400;
                    display: flex;
                    flex-direction:column;

                    span{
                        margin-top: 0.5rem;
                    }
                }
              
        }
        .form-download-btn{
            display: flex;
            flex-direction: column;
            align-items: center;
            .btn--rounded{
                width: 353px;
                height: 51px;
                padding: 16px;
                margin-top: 0.75rem
            }
        }
    }

    .form-header{
        .model-header{
            color: var(--primary-02, $black-2);
            font-size: 24px;
            font-weight: 500;
            margin-bottom: 24px;
        }
        .model-info{
            color: var(--primary-03, $gray-neutral-7);
            font-size: 16px;
            margin-bottom: 2rem;
            font-weight: 400;
        }
        
    }

    label {
        color: $gray-neutral-7;
        display: flex;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.3rem;
        margin-bottom: 0.5rem;
        word-wrap: break-word;
        gap: 8px;
    }
    .data-error-required {
        color: $red;
        margin-bottom: 2rem;
        font-size: 16px;
        width: calc(100% - 1rem);
        display: flex;
        font-style: italic;
    }
    
    .form-content{
        margin-top: 1rem;
        
        &.false{
            opacity: 0.5;
            cursor: not-allowed;
        }

        .upload-file-container label{
            gap: 0px;
            margin-bottom: 0px;
            right: 0;
            font-size: 16px;
            margin-right: 3rem;
        }

        span{
            .data-error-required{
                color: $red;
                margin-bottom: 2rem;
                font-size: 16px;
                width: calc(100% - 1rem);
                display: flex;
                font-style: italic;
            }
        }
        .formInput .error-message {
            font-size: 14px;
            margin-bottom: 2rem;
        }

        .dropdownmenu{
            margin-bottom:2rem;
            .error-message{
                font-style: italic;
            }

            .dropbtn{
                font-size:16px;
                font-style:italic;
                width: calc(100% - 1rem);
                border: 1px solid var(--primary-04, $gray-neutral-4);
                background-position-x: calc(100% - 1rem);
            }
            .selected{
                font-style: normal;
                font-weight: 400;
            }
        }
        
        .formInput{
            .error-message{
                font-style: italic;
                font-size: 16px;
            }
        }
        .react-datepicker-wrapper {
        
            .react-datepicker__input-container {
                input {
                    width: 335px;
                    background: url(../../assets/icons/calendar.svg) no-repeat calc(100% - 1rem);
    
                }
            }
            input[type="text"] {
                height: 54px;
                margin-right: 1.3rem;
            }

            input[type="text"], input[type="number"],
            textarea[type="textarea"] {
                border-radius: 4px;
                border: 1px solid $gray-neutral-4;
                box-sizing: border-box;
                margin-bottom: 0rem;
                padding-left: 1rem;
                width: calc(100% - 1rem);
                &.input-file {
                    display: none;
                }
                &::placeholder {
                    color: $gray-neutral-4;
                }
            }
    
        }
        input[type="text"]{
            height: 54px;
            margin-right: 1.3rem;
        }
        input[type="number"] {
            height: 45px;
            margin-right: 1.3rem;
        }
        input[type="text"],input[type="number"],
        textarea[type="textarea"] {
            border-radius: 4px;
            border: 1px solid $gray-neutral-4;
            box-sizing: border-box;
            margin-bottom: 0rem;
            padding-left: 1rem;
            font-size: 16px;
            font-style: Italic;
            width: calc(100% - 1rem);
            &.input-file {
                display: none;
            }
            &::placeholder {
                color: $gray-neutral-4;
            }
    
            @media (max-width: calc($bp-sidebar-hamburger + 1px)) {
                width: 85vw;
                display: block;
            }
        }
    }

    .form-run-btn{
        width: 100%;
        justify-content: center;
        .btn {
            margin-left: calc(50% - 160px);
            margin-right: 5rem;
            margin-bottom: 4rem;
            margin-top: 3.5rem;
            display: flex;
            width: 335px;
            height: 51px;
            padding: 16px 0px;
            justify-content: center;
            align-items: center;
            border-radius: 100px;
        }
    }
}
.react-datepicker-popper {
    transform: revert;
}
.__react_component_tooltip {
    max-width: 735px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}