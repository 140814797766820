@import "../../assets/scss/variable";

.no-data {
    display: flex;
    justify-content: center;
    padding-top: 3.75rem;

    .no-data--card {
        background: $white;
        border-radius: 8px;
        border: 1px solid $gray-neutral;
        box-shadow: 0px 4px 20px rgba(10, 56, 217, 0.02);
        box-sizing: border-box;
        display: inline-grid;
        height: 235px;
        justify-items: center;
        padding: 1.5rem;
        width: 330px;

        p {
            align-items: center;
            color: $gray-light-2;
            display: flex;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 180%;
            text-align: center;

            &.no-data-header {
                color: $black;
            }
        }
    }
}