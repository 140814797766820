@import "../../assets/scss/variable";

.favorite-page {
    .header {
        @media all and (max-width: $bp-sidebar-top) and (min-width: $bp-favorites-page-toggle) {
            height: 200px;
        }

        @media all and (max-width: $bp-favorites-page-toggle) {
            align-items: unset;
            height: 280px;
        }
    
        @media (max-width: $bp-sidebar-hamburger) {
            height: 260px;
        }
    }

    .bar-blue {
        @media all and (max-width: $bp-sidebar-top) and (min-width: $bp-favorites-page-toggle) {
            top: 360px;
        }
        @media all and (max-width: $bp-favorites-page-toggle) {
            top: 450px
        }
    }

    .back-button {
        @media all and (max-width: $bp-sidebar-top) {
            margin-top: 1rem;
        }
    }

    .loading-spinner {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-top: 8rem;
    }

    .header--title {
        display: inline-flex;
        justify-content: center;
        text-align: center;

        .favorites-page-title {
            text-align: left;
        }

        .view-toggle {
            position: absolute;
            right: 3rem;
            margin-top: 2rem;

            @media all and (max-width: $bp-favorites-page-toggle) {
                position: relative;
                right: 1rem;
                top: 2rem
            }
        }

        @media all and (max-width: $bp-favorites-page-toggle) {
            display: block;
        }
    }

    .data-container {
        .results {
            color: $gray-neutral-7;
            font-size: 1.1rem;
            font-weight: 400;
            line-height: 1.3rem;
            margin-left: 11rem;
            position: relative;
            top: 3.5rem;

            @media all and (max-width: $bp-sidebar-small) and (min-width: calc($bp-sidebar-top + 1px)) {
                margin-left: 9rem;
            }

            @media all and (max-width: $bp-sidebar-top) {
                margin-left: 1.5rem;
            }

            @media (max-width: $bp-favorite-shrink-width) {
                width: calc((100vw - 3rem) / 2);
                word-break: break-word;
            }
        }

        .access-buttons {
            display: inline-flex;
        }

        section {
            table {
                thead {
                    tr {
                        display: none;
                    }
                }

                tbody {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;

                    &:before {
                        display: none;
                    }

                    tr {
                        background: $white;
                        border-radius: 8px;
                        border: 1px solid $gray-neutral;
                        box-shadow: 0px 4px 20px rgba(10, 56, 217, 0.02);
                        display: inline-block;
                        margin-bottom: 1rem !important;
                        margin-right: 0.9rem;
                        padding: 1.5em;
                        width: 397px;
                        position: relative;
                        cursor: pointer;

                        &.orange {
                            border-top: 8px solid $orange-light-30;
                            
                            &:hover {
                                box-shadow:
                                0px 2px 20px rgba(10, 56, 217, 0.1),
                                0 0 0 1px $orange;
                            }
                            &:active {
                                background: $orange-light-20;
                            }
                        }
    
                        &.blue {
                            border-top: 8px solid $blue-light-30;
                            
                            &:hover {
                                box-shadow:
                                0px 2px 20px rgba(10, 56, 217, 0.1),
                                0 0 0 1px $blue-light;
                            }
                            &:active {
                                background: $blue-light-20;
                            }
                        }
    
                        &.purple {
                            border-top: 8px solid $purple-30;
                            
                            &:hover {
                                box-shadow:
                                0px 2px 20px rgba(10, 56, 217, 0.1),
                                0 0 0 1px $purple;
                            }
                            &:active {
                                background: $purple-20;
                            }
                        }

                        @media (max-width: $bp-favorite-shrink-width) {
                            width: calc(100vw - 3rem);
                        }

                        td {
                            height: 40px;

                            @media (max-width: $bp-favorite-shrink-width) {
                                width: calc(100vw - 3rem);
                            }

                            &:first-child {
                                align-items: center;
                                color: $black-2;
                                font-size: 1rem;
                                font-weight: 500;
                                width: calc(100% - 30px);
                                word-break: break-word;

                                img.arrow-separator {
                                    height: 10px;
                                    width: 10px;
                                    transform: rotateZ(90deg);
                                    margin-left: 0.3rem;
                                }
                            }

                            &:nth-child(2) {
                                display: none;
                            }

                            &:nth-child(3) {
                                font-size: 0.7rem;
                                font-weight: 500;
                                text-transform: uppercase;
                                width: 320px;

                                .__react_component_tooltip {
                                    text-transform: none;
                                }

                                .report-type {
                                    display: inline-flex;
                                    align-items: center;

                                    img {
                                        width: 16px;
                                        height: 16px;
                                        padding-right: 0.3rem;
                                    }

                                    p {
                                        font-weight: 500;
                                        text-transform: uppercase;
                                        color: $gray-light-2;
                                    }

                                    .green {
                                        color: $green;
                                    }

                                    .dark-blue {
                                        color: $blue-dark-2;
                                    }
                                }

                                .dot-separator {
                                    font-size: 1rem;
                                    padding: 0.2rem 0.8rem;
                                }

                                p {
                                    font-size: 0.75rem;
                                }

                                .green {
                                    color: $green;
                                }
                            }

                            &:nth-child(4) {
                                display: inline-grid;

                                .no-access,
                                .access-single,
                                .access {
                                    border-radius: 0px 0px 8px 8px;
                                    bottom: -1.3rem;
                                    font-size: 1rem;
                                    font-weight: 700;
                                    height: 43px;
                                    left: -1.5rem;
                                    width: 395px;
                                    transition: none;

                                    @media (max-width: $bp-favorite-shrink-width) {
                                        width: calc(100% - 2px);
                                    }
                                }

                                .favorite {
                                    position: absolute;
                                    top: 1.75rem;
                                    right: 1.5rem;

                                    &.share-icon {
                                        left: -10rem;
                                        margin-bottom: 0rem;
                                    }
                                }

                                .left {
                                    border-radius: 0px 0px 0px 8px;
                                }

                                .right {
                                    border-radius: 0px 0px 8px 0px;
                                }
                            }
                        }
                    }
                }
            }

            .dropdown-row {
                margin: 0.5rem 1.3rem 0 10.5rem;
            }
        }

        .favorites-unavailable {
            margin-top: 5rem;
        }
    }
}