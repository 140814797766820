@import "../../assets/scss/variable";

.toast-container {
  position: fixed;
  z-index: 10;
  bottom: 1rem;
  right: 1rem;
  animation: toast-right 0.7s;

  .toast {
    background-color: $white;
    width: 657px;
    bottom: 1rem;
    right: 1rem;
    margin-bottom: 1rem;
    border-radius: 5px;
    border: solid $gray-neutral;
    border-width: 1px 1px 1px 7px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    animation: toast-right 0.7s;
    color: $black-2;
    justify-content: space-between;

    .toast-content {
      display: flex;

      .icon {
        margin: 2rem 0 0 1.2rem;
        height: 32px;
        width: 32px;
      }

      .text {
        margin: 1rem 0 0.5rem 1rem;

        button{
          text-decoration: underline;
          text-decoration-color: $blue;
          text-decoration-thickness: 1px;
        }
      }

      .heading {
        color: $black-2;
        font-weight: 500;
      }

      .subtitle {
        margin-top: 0.4rem;
        color: $gray-light-2;
        margin-bottom: 0.2rem;
      }
    }
  }

  .error {
    border-left: 7px solid $red;
  }

  .success {
    border-left: 7px solid $green;
  }

  button {
    background-color: unset;
    border: none;
    color: $black-2;
    cursor: pointer;
    margin-right: 1rem;
    transition: 0.3s ease all;
  }
}

@keyframes toast-right {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
