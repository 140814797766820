@import "../../../assets/scss/variable";

.back-button {
    margin-bottom: 0;

    @media all and (max-width: $bp-sidebar-hamburger) {
        margin-top: 3.5rem;
    }

    display: flex;
    margin: 0 0 0 10rem;
    text-align: left;
    text-decoration: none;
    align-items: center;
    cursor: pointer;
    
    @media (min-width: calc($bp-sidebar-top + 1px)) and (max-width: $bp-sidebar-small) {
        margin-left: 9rem;
    }
    
    @media (min-width: calc($bp-sidebar-hamburger + 1px)) and (max-width: $bp-sidebar-top) {
        margin: 0 0 0rem 1.5rem;
    }
    
    @media all and (max-width: $bp-sidebar-hamburger) {
        margin: 0 0 0 1.5rem;
    }
    
    p {
        color: $gray-neutral-7;
        display: inline-flex;
        font-weight: 400;
        width: 143px;
        font-size: 16px;
        word-break: break-all;
    
        &:active {
            color: $blue;
        }
    }
    
    span {
        color: $gray-light-2;
        font-weight: 100;
        padding-top: 0.3rem;
    }
}