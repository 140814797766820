@import "../../assets/scss/variable";

.navbar {
    background: $purple-light;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.02);
    min-height: 96px;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 1;

    @media (max-width: $bp-sidebar-hamburger) {
        min-height: 80px;
    }

    .navbar-buttons {
        text-align: right;
        margin: 1.5rem 1rem 0 0;
        .add-mktpl-btn {
            font-weight: 500;
            @media (min-width: $bp-sidebar-top) {
                border: 1px solid $blue-dark-3;
                color: $white;
                background-color: $blue-dark-3;
                height: 48px;
                padding: 8px 16px;
            }

            &:hover {
                background-color: $white;
                color:$blue-dark-3;
                border: 1px solid $blue-dark-3;
                box-sizing: border-box;
                box-shadow: 0px 4px 40px rgba(123, 121, 122, 0.2);
            }
        }

        .modal-main {
            text-align: justify;
        }

        @media (max-width: $bp-sidebar-hamburger) {
            display: none;
        }
    }

    @media all and (max-width: $bp-sidebar-top) {
        background: $blue-dark-3;
    }
}