@import "../../assets/scss/variable";

.detail-card {
    align-items: center;
    border-radius: 8px;
    background: $white;
    border: 1px solid $gray-neutral;
    box-shadow: 0px 2px 20px rgba(10, 56, 217, 0.1);
    box-sizing: border-box;
    padding: 2rem 1.3rem 1rem;
    position: relative;
    text-align: center;
    min-width: 351px;

    &--title {
        font-weight: 500;
        font-size: 16px;
        padding-bottom: 1rem;
        color: $black;
    }

    &--subtitle {
        color: $gray-neutral-7;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 180%;
        margin-bottom: 1.5rem;
    }
}