@import "../../assets/scss/variable";

.switch-container {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
}

.selected {
    transition: font-weight 0.3s ease;
    font-weight: 500;
}

.toggle-switch {
    position: relative;
    display: inline-block;
    width: 52px;
    height: 24px;
}

.toggle-switch input[type="checkbox"] {
    display: none;
}

.toggle-switch .switch {
    position: absolute;
    cursor: pointer;
    background-color: $gray-neutral-4;
    border-radius: 25px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 0.3s ease;
}

.toggle-switch .switch::before {
    position: absolute;
    content: "";
    left: 5px;
    top: 4px;
    width: 16px;
    height: 16px;
    background-color: $gray-neutral;
    border-radius: 50%;
    transition: transform 0.3s ease;
}

.toggle-switch input[type="checkbox"]:checked + .switch::before {
    transform: translateX(25px);
    background-color: $gray-neutral;
}

.toggle-switch input[type="checkbox"]:checked + .switch {
    background-color: $blue;
}