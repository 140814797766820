@import "../../assets/scss/variable";

.not-found--container {
    .app-container {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin: 3rem 0 0 9rem;

        span {
            margin: 5rem 0 0 5rem;
            color: $black-2;

            @media all and (max-width: $bp-sidebar-top) {
                margin: 10rem 0 0 0;
            }
        }
    }
}