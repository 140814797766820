@import "../../assets/scss/variable";

.accordion--item.active .button {
    background-color: #105057;
}

.filter--wrapper {
    height: 0;
    overflow: hidden;
    transition: height ease 0.2s;
}

.filter--wrapper.open {
    height: 100px;
}

.button-search {
    background-color: $blue;
    border-radius: 0 12rem 12rem 0;
    color: $white;
    font-weight: bold;
    height: 3rem;
    left: calc(50% - 118px / 2 + 276px);
    position: absolute;
    width: 188px;
}

.button-view {
    background-color: $blue-light-10;
    border-radius: 200px;
    color: $blue;
    font-family: "Effra", sans-serif;
    font-size: 1rem;
    font-weight: 100;
    line-height: 1.1rem;
    padding: 8px 30px;
    text-decoration: none;

    &:hover {
        background-color: $blue-light-20;
        transition: 0.2s ease-in-out;
    }

    &:active {
        background-color: $blue;
        color: $white;
    }
}

div {

    &#name,
    &#Edit {
        display: none;
    }
}

img {
    &.typeIcon {
        padding-right: 0.5rem;
        width: 20px;
    }
}

.main-datatable {
    display: flex;
    flex-direction: column;
    margin-left: 4.5rem;
    padding: 0 3% 4rem;
    position: relative;
    transition: 300ms ease-in-out;
    width: 100%;
    z-index: 2;

    &--expanded {
        margin-left: 251px;
    }
}

.modal-active {
    input {
        pointer-events: none;
        opacity: 0.85;
    }
}

.search-spinner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;

    &:first-child {
        margin: auto;
    }

    span {
        color: $gray-light-2;
        padding: 1em;
    }
}

.results-label {
    bottom: 1.1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.1rem;
    margin-right: 1.5rem;
    position: absolute;
    right: 0;
}

.react-tooltip--text {
    p {
        font-size: 0.8rem;
        line-height: 0.5rem;
    }
}

.link-results {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    >p {
        color: $gray-neutral-7;
        font-size: 1rem;
        font-weight: 400;
        margin: 6.5rem 1.9rem 0;

        @media all and (max-width: $bp-sidebar-top) {
            margin: 9.5rem 1.9rem 0;
        }

        @media (max-width: $bp-sidebar-hamburger) {
            margin: 13.5rem 1rem 0;
        }
    }
}

.loading-error {
    display: flex;
    margin: 2rem 0 2rem 11rem;
    text-align: left;
    text-decoration: none;
    cursor: pointer;

    span {
        color: $red;
        font-weight: 100;
        padding-top: 0.3rem;
    }
}

.no-results-message {
    margin: 0 0 2rem 10.5rem;

    @media (min-width: $bp-sidebar-top) and (max-width: $bp-sidebar-small) {
        margin-left: 8rem;
    }

    @media all and (max-width: $bp-sidebar-top) {
        margin-left: 1.9rem;
    }
}

table {
    border-collapse: separate;
    border-spacing: 0px;
    color: $gray-light-2;
    font-size: 1rem;
    font-weight: 100;
    margin-left: 10rem;
    min-width: calc(100% - 10rem);

    @media all and (max-width: $bp-search-table-small) {
        margin: 1rem;
        min-width: calc(100% - 1.5rem);
    }

    @media (max-width: $bp-sidebar-small) and (min-width: calc($bp-sidebar-top + 1px)) {
        margin-left: 9rem;
    }

    @media (max-width: $bp-sidebar-top) {
        margin-left: 1rem;
        min-width: calc(100% - 2rem);
    }

    thead {
        background-color: $gray-neutral-2;

        tr {
            align-items: center;
            border-radius: 8px;
            box-sizing: border-box;
            display: flex;
            height: 46px;
            justify-content: space-around;

            th {
                color: $gray-light-2;
                font-size: 0.75rem;
                font-style: normal;
                font-weight: 600;
                text-transform: uppercase;
                min-width: 170px;
                text-align: start;

                &:first-child {
                    justify-content: start;
                    width: 240px;
                }

                &:nth-child(4) {
                    width: 190px;
                }

                &:nth-child(6) {
                    width: 180px;
                }
            }
        }
    }

    tbody {
        &:before {
            color: $white;
            content: ".";
            display: block;
            line-height: 1em;
        }

        .table-row {
            border-radius: 200px;
            color: $gray-light-2;
            font-family: "Effra", sans-serif;
            text-decoration: none;
        }

        tr {
            align-items: center;
            background: $white;
            border-radius: 8px;
            border: 1px solid $gray-neutral;
            box-shadow: 0px 4px 20px rgba(10, 56, 217, 0.02);
            box-sizing: border-box;
            display: flex;
            min-height: 100px;
            justify-content: space-around;
            margin-bottom: 10px !important;
            position: static;

            td {
                align-items: center;
                display: flex;
                flex-direction: row;

                button {
                    border: none;
                }

                a {
                    cursor: pointer;
                }

                span {
                    padding-bottom: 0.5rem;
                }

                p.overflow {
                    display: none;

                    &#owner_0 {
                        display: block;
                    }
                }
            }
        }
    }
}

.pagination {
    display: flex;
    justify-content: space-between;
    margin: 1rem 1.5rem 2rem 10rem;

    @media (max-width: $bp-sidebar-small) and (min-width: calc($bp-sidebar-top + 1px)) {
        margin-left: 9rem;
    }

    @media (max-width: $bp-sidebar-top) {
        margin-left: 1.5rem;
    }

    &--left,
    &--right {
        button {
            background: none;
            border: none;
            padding: 0;
        }
    }

    &--left {
        align-items: center;
        display: flex;

        button {
            &:disabled {
                .circle--blue {
                    background: $white;
                    border: 1px solid $gray-neutral;
                    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
                    color: $gray-neutral-4;
                }
            }

            .circle--blue {
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        @media (max-width: $bp-datatable-pagination-center) {
            margin: auto;
        }
    }

    &--numbers {
        background: $gray-neutral-2;
        border: 1px solid $gray-neutral-5;
        border-radius: 200px;
        padding: 0.2rem 0.8rem;
        line-height: 1rem;
        font-size: 16px;
        margin-right: 0.5rem;

        input {
            border: none;
            width: 2.8rem;
            height: 28px;
            text-align: center;
            font-weight: 400;
            font-family: "Effra";
            font-size: 16px;
            border-radius: 200px;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

            &:focus {
                outline-width: 0;
            }
        }

        &-first {
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
            background: $white;
            border-radius: 200px;
            margin: 0 0.5rem 0 -0.5rem;
        }
    }

    &--right {
        select {
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            background: transparent url(../../assets/icons/arrow-select-down.svg) no-repeat 39px center;
            border: none;
            padding: 2px 2px 2px 2px;
            text-indent: 1px;
            text-overflow: "";

            &:hover {
                background-color: $gray-neutral-5;
                transition: 0.2s ease-in-out;
            }

            &:focus {
                background: transparent url(../../assets/icons/arrow-select-up.svg) no-repeat 39px center;
                transition: 0.1s ease-in-out;
            }

            &.dropdown-page {
                border-radius: 200px;
                border: 1px solid $gray-neutral;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
                box-sizing: border-box;
                margin-right: 0.7rem;
                padding: 0.65rem;
                width: 77px;
            }
        }

        span {
            color: $black-2;
            font-size: 1rem;
            font-weight: 200;
            line-height: 1.1rem;
        }

        @media (max-width: $bp-datatable-pagination-center) {
            display: none;
        }
    }
}

.tooltip {
    position: relative;
    justify-content: start;
    margin-left: 1rem;
    width: 240px;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: clip;

    .tooltiptext {
        visibility: visible;
        width: 246px;
        background-color: rgb(0 0 0 / 47%);
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        position: relative;
        left: 68%;
        margin-left: -60px;
        margin-bottom: 3rem;

        &:hover {
            visibility: visible;
            transition: 0.2s ease-in-out;
        }
    }
}

.dropdown-row {
    display: flex;
    margin: 0 1rem 1.5rem 10.5rem;
    justify-content: space-between;

    @media (max-width: $bp-sidebar-small) {
        .dropdown--filter {
            margin-right: .5rem;
        }
    }

    @media (min-width: calc($bp-sidebar-top + 1px)) and (max-width: $bp-sidebar-small) {
        margin-left: 9rem;
    }

    @media (max-width: $bp-sidebar-top) {
        margin-left: 1.5rem;
    }

    @media (max-width: $bp-sidebar-hamburger) {
        display: block;
        margin: 1.5rem .5rem -1rem 1rem;
    }

    .filters {
        display: flex;

        @media (max-width: $bp-sidebar-hamburger) {
            display: none;
        }
    }

    .filter-row--right {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .sort-container {
        display: inline-flex;

        @media (max-width: $bp-table-hide-filters) {
            width: 100%;
            justify-content: space-between;
        }
    }

    .dropdown-item {
        @media (max-width: $bp-sidebar-hamburger) {
            #primaryOwner {
                margin-bottom: 7rem;
            }

            button {
                display: none;
            }
        }
    }

    .view-filters {
        .dropdown-btn {
            width: 132px;

            &.dropdown-active {
                border: 1px solid $blue-light;
                background: unset;
                color: unset;

                .dropdown-btn__counter {
                    color: $blue;
                }
    
                .dropdown-btn__arrow {
                    background: $blue-light-10;
                }
    
                &:hover {
                    background: $blue-light-10;
                    border: 1px solid $blue-light;
                }
            }

            &:hover {
                color: unset;
                .dropdown-btn__arrow {
                    background: $blue-light-10;
                }
            }
        }
    
        .sort-dropdown-content {
            background: $white;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
            color: #333;
            display: none;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            right: 11px;
            padding: 3px 0 3px 0;
            position: absolute;
            z-index: 10;
            top: 3rem;
            width: 200px;
            height: auto;
            overflow: auto;
            // border-radius: ;
    
            .dropdown-item {
                cursor: pointer;
                transition: all 0.2s;
                padding: 10px;
            }
    
            &.dropdown-show {
                display: block;
            }
    
            .input-container {
                display: flex;
                justify-content: flex-start;
            }
        }
        &.allFilters {
            display: none;
            margin-right: 0.7rem;

            @media all and (max-width: $bp-table-hide-filters) {
                display: block;

                .overlay {
                    background: rgba(0, 0, 0, 0.2);
                    height: 100%;
                    left: 0;
                    position: fixed;
                    top: 0;
                    width: 100%;
                    z-index: 22;
                }
            }

            .view--clear-all {
                align-items: center;
                display: flex;
                margin-left: 12rem;

                @media (max-width: $bp-table-hide-filters) {
                    margin-left: 10rem;
                }
            }

            .sort-dropdown-content {
                border: 1px solid $gray-neutral;
                right: -151px;
                top: 3rem;
                width: 350px;
                border-radius: 8px;

                button {
                    &.btn--block {
                        bottom: 0rem;
                        height: 61px;
                        position: fixed;
                        width: calc(350px - 2rem);
                        z-index: 10;
                    }
                }

                @media all and (max-width: $bp-table-hide-filters) {
                    left: 0rem;
                    position: fixed;
                    top: 6rem;
                    bottom: 0;
                    z-index: 22;
                    width: calc(350px - 2rem);
                }

                .filters--container {
                    height: calc(100% - 59px - 8.75rem);
                    overflow-y: auto;
                    overflow-x: hidden;
                }

                .dropdown--filter {
                    margin: 0rem 1rem 0rem 0;

                    .dropdown-btn {
                        border-bottom: none;
                        border-left: none;
                        border-radius: 0px;
                        border-right: none;
                        border-top: 1px solid $gray-neutral;
                        box-shadow: none;
                        margin: 0 1rem;
                        min-width: 87%;
                        padding: 1.6rem 0.87rem;

                        @media all and (max-width: $bp-table-hide-filters) {
                            padding: 1.2rem 0.87rem;
                        }

                        &:hover {
                            background-color: transparent;
                        }
                    }

                    .dropdown-content {
                        max-height: fit-content;
                        border-radius: unset;

                        &.dropdown-show {
                            background-color: $purple-light;
                            border: none;
                            box-shadow: none;
                            overflow: hidden;
                            position: relative;
                            top: auto;
                            transition: width 0.2s ease-out;
                            width: 350px;

                            @media all and (max-width: $bp-sidebar-hamburger) {
                                border: none;
                                width: calc(350px - 2rem);
                            }
                        }

                        .dropdown-item {
                            .search-option {
                                svg {
                                    top: 2.5rem;
                                }
                            }

                            .dropdown-item-header {
                                display: none;
                            }
                            #primaryOwner {
                                max-height: 27rem;
                                overflow: hidden;
                                overflow-y: scroll;

                            }
                        }

                        .input-container {
                            label {
                                font-weight: 400;
                            }
                        }
                    }
                }

                .header-filters {
                    align-items: center;
                    display: flex;
                    justify-content: space-between;
                    margin: 1.5rem 1rem;

                    p {
                        font-size: 1rem;
                        font-weight: 500;
                    }

                    .dropdown--filter {
                        button {
                            font-size: 16px;
                            font-weight: 500;
                            min-width: unset;
                            margin-left: 1rem;
                        }
                    }
                }
            }
        }
    }

    .sort {
        .dropdown-btn {
            width: 132px;

            &:hover {
                background-color: $blue-light-5;
                color: $black-2;

                .dropdown-btn__arrow {
                    background: $blue-light-10;
                }
            }
        }
    
        .sort-dropdown-content {
            background: $white;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
            color: #333;
            display: none;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            right: 11px;
            padding: 3px 0 3px 0;
            position: absolute;
            z-index: 10;
            top: 3rem;
            width: 200px;
            height: auto;
            overflow: auto;
            border-radius: 8px;
    
            .dropdown-item {
                cursor: pointer;
                transition: all 0.2s;
                padding: 10px;
    
                &:hover {
                    background-color: $blue;
                    color: $white;
                }
            }
    
            &.dropdown-show {
                display: block;
            }
    
            .input-container {
                display: flex;
                justify-content: flex-start;
            }
        }
        span {
            &.sort--title {
                color: $gray-light-2;
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 0.02em;
                line-height: 14px;
                position: absolute;
                text-transform: uppercase;
                padding: 0.2rem;
            }
        }

        .dropdown--filter {
            margin-right: 0;
        }
    }
}

.dropdown--filter {
    border-radius: 200px;
    box-sizing: border-box;
    margin: 2rem 1rem 2rem 0;
    position: relative;
    user-select: none;

    .dropdown-btn {
        align-items: center;
        background: #fff;
        border-radius: 200px;
        border: 1px solid $gray-neutral;
        box-shadow: 0px 2px 20px rgba(10, 56, 217, 0.1);
        color: $black-2;
        cursor: pointer;
        display: flex;
        font-size: 14px;
        font-weight: 400;
        justify-content: space-between;
        line-height: 17px;
        padding: 8px 7px 8px 10px;
        white-space: nowrap;

        div {
            white-space: nowrap;
        }

        &.dropdown-active {
            background: $blue;
            color: $white;

            .dropdown-btn__arrow {
                background: $white;
            }
        }

        &:active {
            background: $blue;
            color: $white;
        }

        &:hover {
            background: $blue;
            color: $white;
            transition: 0.3s ease-in-out;

            .dropdown-btn__arrow {
                background: $white;
            }
        }

        &--red {
            &:hover {
                background: $red-light-5;
                transition: 0.3s ease-in-out;
                color: $black-2;

                .circle--red {
                    background: $red-light-20;
                }
            }

            &:active {
                background: $red-1;
                border: 1px solid $red;
                color: $white;

                .circle--red {
                    background: $white;
                }
            }
        }

        span {
            &.dropdown-btn__counter {
                color: $white;
                display: inline-flex;
                margin-left: 0.2rem;
            }

            &.dropdown-btn__arrow {
                background: $blue-light-10;
                border-radius: 200px;
                display: inline-flex;
                height: 23px;
                margin-left: 0.5rem;
                width: 23px;
                justify-content: center;
                align-items: center;

                &:active {
                    background: $white;
                }

                svg {
                    color: $blue;

                    polyline {
                        stroke-width: 30;
                    }
                }
            }
        }

        &.filter-all {
            padding: 8px 10px 8px 7px;

            span.dropdown-btn__arrow {
                margin-left: 0;
                margin-right: 0.5rem;
            }

            &:hover {
                color: $white;

                span.dropdown-btn__arrow {
                    background: $white;
                }
            }
        }
    }

    .dropdown-content {
        background: $white;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        color: #333;
        display: none;
        font-weight: 500;
        left: 0;
        position: absolute;
        z-index: 10;
        top: 3rem;
        width: 341px;
        max-height: 35rem;
        overflow: auto;
        border-radius: 8px;

        .dropdown-item {
            cursor: default;
            transition: all 0.2s;
            padding-bottom: 1rem;

            Button {
                margin: 1rem 0px 12px 2.5%;
            }

            .search-option {
                display: inline-flex;

                input[type="text"] {
                    background-color: transparent;
                    border-bottom: 2px solid $gray-neutral-4  !important;
                    border: none;
                    height: 30px;
                    margin: 1rem 1.5rem 1.5rem;
                    width: 293px;
                    outline: none;

                    @media all and (max-width: $bp-table-hide-filters) {
                        width: 260px;
                    }

                    &::placeholder {
                        color: $gray-neutral-4;
                        font-size: 1.1rem;
                        line-height: 1.3rem;
                        padding-bottom: 0.5rem;
                    }
                }

                svg {
                    position: absolute;
                    right: 2rem;
                    top: 2rem;
                    color: $blue-light;

                    circle,
                    line {
                        stroke-width: 25;
                    }
                }
            }

            .dropdown-item-header {
                background-color: $green-10;
                height: 72px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: $black-2;
                font-weight: 400;
                margin-bottom: 1rem;

                p {
                    font-size: 16px;
                    font-weight: 500;
                    margin-left: 1rem;
                }
                
                .header-buttons {
                    display: flex;
                    button {
                        font-weight: 500;
                        min-width: unset;
                        margin-right: 1rem;
                    }
                }
            }
        }

        &.dropdown-show {
            display: block;
            overflow-x: hidden;
        }

        .input-container {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            &:hover {
                background: $gray-neutral-2;
            }

            input[type="checkbox" i]:before {
                content: "";
            }

            input {

                &[type="checkbox"],
                &[type="radio"] {
                    background: $white;
                    border-radius: 4px;
                    border: 1px solid $gray-neutral-3;
                    box-sizing: border-box;
                    height: 1.5rem;
                    width: 1.5rem;
                    margin-left: 1rem;
                    cursor: pointer;

                    &:checked {
                        color: $blue;
                        border: 2px solid $blue;
                    }
                }

                &[type="checkbox" i] {
                    &:before {
                        background: url(../../assets/icons/tick.svg);
                    }
                }
            }

            label {
                align-items: center;
                color: $black-2;
                display: flex;
                flex-grow: 1;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.1rem;
                padding: 10px 23px 10px 0.5rem;
                cursor: pointer;
            }
        }
    }
}