@import "../../../assets/scss/variable";

.favorites-group-card {
    background: $white;
    border-radius: 8px;
    border: 1px solid $gray-neutral;
    box-shadow: 0px 2px 20px rgba(10, 56, 217, 0.1);
    box-sizing: border-box;
    display: block;
    margin: 0.75rem 0 0.75rem 0.75rem;
    height: 303px;
    position: relative;
    min-width: 397px;

    hr {
        background-color: $gray-neutral;
        border: 0;
        height: 1px;
        size: 1px;
        width: 100%;
    }

    .link {
        text-decoration: none;
    }

    .group-card-header {
        position: relative;
        justify-content: space-between;
        align-items: center;
        background-color: $green-1;
        display: flex;
        border-radius: 8px 8px 0 0;
        
        .header-title,
        .header-subtitle {
            display: inline-flex;
            align-items: center;
            color: $white;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.02em;
            line-height: 19px;
            margin-left: 1.5rem;
            padding: 1.5rem 0;
            text-transform: uppercase;
            float: left;
            img {
                width: 24px;
                height: 24px;
                margin-right: 0.5rem;
                stroke: black;
            }
        }
    
        .header-subtitle {
            font-weight: 400;
            float: right;
            margin-right: 1.5rem;
            line-height: 21.6px;
        }
    }


    &--items {
        display: block;
        height: 100%;
        padding-bottom: 1rem;
        max-height: 180px;
        overflow-x: hidden;
        overflow-y: auto;
    }

    &--items:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        pointer-events: none;
        background-image: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255, 1) 50%);
        width: 100%;
        height: 4em;
        border-radius: 0 0 8px 8px;
    }

    .card-item {
        background: $white;
        border-bottom: 2px solid $gray-neutral;
        display: inline-flex;
        align-items: center;
        width: 100%;
        height: 56px;
        padding-left: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        position: relative;

        &.orange {
            border-left: 5px solid $orange-light-30;
        }
        &.blue {
            border-left: 5px solid $blue-light-30;
        }
    
        &.purple {
            border-left: 5px solid $purple-30;
        }

        .item-header {
            display: inline-flex;
            align-items: center;
            width: 100%;
            .item-name {
                text-overflow: ellipsis;
                max-width: 13.5rem;
                overflow: hidden;     
                white-space: nowrap;
            }
        
            img.arrow-separator {
                height: 10px;
                width: 10px;
                transform: rotateZ(90deg);
                margin-left: 0.3rem;
            }
        }

        .item-actions {
            display: inline-flex;
            margin-right: 1.5rem;
            svg {
                margin-right: 1rem;
            }
        }
    }
}