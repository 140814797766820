@import "../../assets/scss/variable";

.manage-tab {
    padding-top: 8rem;
    font-family: "Effra", sans-serif;
    font-size: 17px;
    color: #999999;
    margin-left: -.4em;

    ul.nav {
        margin: 0 auto .5rem;
        margin-left: 10rem;
        display: flex;

        @media (max-width: 768px) {
            width: 90%;
        }
    }

    ul.nav li {
        padding-left: 1rem;
        padding-right: 1rem;
        list-style: none;
        cursor: pointer;
        transition: all 0.2s;
    }

    ul.nav li:hover {
        color: #39A2DB;
    }

    ul.nav li.active {
        color: #39A2DB;
    }

    border-bottom: 1px solid $gray-neutral-4;

    @media all and (max-width: $bp-sidebar-top) {
        padding-top: 10rem;
        margin: 1rem 0 0 -9rem;
    }

    @media all and (max-width: $bp-sidebar-hamburger) {
        padding-top: 12.5rem;
    }
}

.admin-content {
    .header {
        margin-top: 1rem !important;
        height: 115px;
    }
}