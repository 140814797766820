@import "../../assets/scss/variable";

.recommended--container {
    .tab--container {
        .tab--holder {
            border-bottom: 4px solid $green-1;
        }

        @media all and (max-width: $bp-sidebar-hamburger) {
            margin-top: 2rem;
        }
    }

    .tab--panel-container {
        padding: 2rem;

        .recommended-last-updated {
            width: fit-content;
            position: relative;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 21px;
            color: $gray-neutral-7;
            margin-bottom: 2rem;
        }
    }
    
    .loading-spinner {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-top: 8rem;
    }
    
    .back-button {
        @media all and (max-width: $bp-sidebar-top) {
            margin-top: 1rem;
        }
    }

  
    .tutorial-contianer{
        margin-top:50px;
    }
}

