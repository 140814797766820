@import "../../assets/scss/variable";

.actions-dropdown {
    position: relative;

    .action-btn {
        border-radius: 8px;
        min-width: 32px;
        height: 32px;
        color: $gray-light-2;
        padding-bottom: 10px;
        font-size: large;
    }
    
    .actions-dropdown-content {
        background: $white;
        border: 1px solid $gray-neutral;
        box-shadow: 0px 2px 20px rgba(10, 56, 217, 0.1);
        color: #333;
        display: none;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        right: -1px;
        padding: 3px 0 3px 0;
        position: absolute;
        z-index: 10;
        top: 2.5rem;
        width: 200px;
        max-height: 35rem;
        overflow: auto;
        border-radius: 4px;
        color: $black-2;

        .dropdown-item {
            cursor: pointer;
            transition: all 0.2s;
            padding: 10px;

            &:hover {
                color: $white;
                background: $blue;
                path {
                    stroke: $white;
                }
            }
        }

        &.dropdown-show {
            display: block;
        }

        .input-container {
            display: inline-flex;
            justify-content: flex-start;
            align-items: center;

            &.disabled {
                cursor: not-allowed;
            }
            
            svg {
                margin-right: 5px;
                path {
                    stroke: $black;
                }
            }
        }
    }
}