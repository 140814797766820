@import "../../../assets/scss/variable";

.manage-personas{

.manage-persona-container {

    &--inactive{
        display:none;
    }
    .header-container {
        display: flex;

        .header--title {
            margin-left: 10.5rem;

            @media (max-width: $bp-sidebar-top) {
                margin-left: 2rem;
              }
        }
    }

    .persona-icon-container{
        margin-left: 10.5rem;
        margin-top: 40px;
        margin-right: 40px;

        @media (max-width: $bp-sidebar-top) {
            margin-left: 2rem;
          }

        .no-personas-error {
            margin-top: 1rem;
            span {
                color: $red;
            }
        }

        .tab-header{
            padding: 24px;
            border-radius: 8px 8px 0px 0px;
            border: 1px solid var(--primary-06, $gray-neutral);
            background: var(--primary-sea, $green-1);
            gap: 8px;
            display: flex;
            align-items: flex-start;
            span{
                color: var(--primary-07, $white);
                font-size: 16px;
                font-weight: 500;
                line-height: normal;
                letter-spacing: 0.32px;
            }
        }

        .persona-icons-grid{
            display: inline-grid;
            gap: 24px;
            border-radius: 8px;
            padding: 24px;
            border: 1px solid var(--primary-06, $gray-neutral);
            background: var(--primary-07, $white);
            /* Drop Shadow */
            box-shadow: 0px 2px 20px 0px rgba(48, 99, 197, 0.10);
            grid-template-columns: repeat(3, minmax(300px, 835px));
            height: 300px;

            .--persona-column{
                border-radius: 8px;
                border: 1px solid var(--primary-06, $gray-neutral);
                background: var(--neutral-white, $white);
                box-shadow: 0px 2px 20px 0px rgba(48, 99, 197, 0.10);
                display: flex;
                flex-direction: column;
                align-items: center;

                &--inactive {
                    display: none;
                }

                .--persona-icon{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    position: relative;
                    top: 20%;
                }

                .action-button{
                    width: 100%;
                    margin-top: 1rem;
                    margin-right: 1rem;

                    img{
                        float: right;
                    }

                    .btn{
                        display:none;
                        float: right;
                        &--blue-light.wide{
                            height: auto;
                            width: auto;
                        }
                    }
                }
            }

            .--persona-column:hover{
                border: 1px solid var(--primary-sea, $green-1);

                .action-button{
                    img{
                        display: none;
                    }
                    .btn{
                        display: block;
                    }
                }
            }
        }
    }
}

.persona-management-container{

    &--inactive{
        display: none;
    }

    .back-button{
        margin-top: 24px;
    }
    .results-datatable{
        &--user-group-datatable{
            .datatable{
                thead{
                    display: none;
                }
                tbody{
                    display: inline-grid;
                    grid-template-columns: 50% 50%;
                    width: 100%;

                    &:before{
                        display: none;
                    }

                    tr{
                        grid-template-columns: 1fr;
                        td{
                        display: grid;
                        //justify-content: space-between;
                        grid-template-columns: 20% 60% 20%;
                    }}
                }
            }
        }
    }

    .datatable {
        padding-bottom: 10px;
        padding-right: 30px;

        .no-results-message {
            font-size: 16px;
            margin-left: 2.5rem;
        }

        .pagination{
            margin-left: 2.5rem;
        }

        .dropdown-row {
            margin: 0;

            .sort .dropdown--filter{
                margin-bottom: 1rem;
            }
            .sort span.sort--title {
                margin-top: 0.5rem;
            }

            @media (max-width: $bp-product-container-medium) {
                justify-content: flex-end;
                width: 100%;
            }
        }

        table{
            margin-left: 2.5rem;
            width: 100%;
           // width: calc(100% - 2rem);
        }

        table tbody:before {
            line-height: 0;
        }

        thead {
            //justify-content: space-between;
            
            tr{
                border-radius: 8px;
                background: var(--primary-sea, $green-1);
                border: 1px solid var(--primary-06, $gray-neutral);
                border-left: none;
                margin-bottom: 10px;

                &:hover{
                    border: revert;
                }
                th{
                    color: var(--primary-07, $white);
                    font-size: 12px;

                    &:first-child{
                        margin-left: 15px;
                    }   
                }
            }
            
           // display: none;
        }

        tr {

            border-radius: 8px;
            border: 1px solid var(--primary-06, $gray-neutral);
            border-left: 5px solid var(--30-sea, $green-30);
            background: var(--neutral-white, $white);
            /* Drop Shadow */
            box-shadow: 0px 2px 20px 0px rgba(48, 99, 197, 0.10);
            min-height: 61px;
            align-items: center;
            justify-content: space-between;
           // margin-right: 2rem;
            padding-right: 2rem;
            display: inline-grid;
            grid-template-columns: 1fr 1fr 20%;
            width: calc(100% - 2rem);

            td {
                gap: 8px;
                align-items: center;
                justify-content: start;
                .persona-user-icon{
                    width: 40px;
                    height: 40px;
                    margin-left: 16px;
                }
    
                .persona-remove-icon{
                    width: 24px;
                    height: 24px;
                    margin-left: 10px;
                }
    
                span{
                    font-size: 16px;
                    font-weight: 500;
                    font-style: normal;
                    color: var(--primary-02, $black-2);
                }
                .persona-user-email{
                    color: var(--primary-03, $gray-neutral-7);
                }
            }
        }

        tr:hover{
            border: 1px solid var(--primary-sea, $green-1);
            border-left: 5px solid var(--30-sea, $green-30);
        }
    } 

    .tab{
        &--container{
            margin-left: 8.5rem;
            @media (max-width: $bp-sidebar-top) {
                margin-left: 0rem;
              }
        }  
        
        &--panel-container{
            margin-left: 8.5rem;

            @media (max-width: $bp-sidebar-top) {
                margin-left: 0rem;
              }
        }
    }

    .persona-description-container{
        display: flex;
        flex-direction: row;
        .persona-description{
            margin-left:10rem;
            margin-top: 1.5rem;

            @media (max-width: $bp-sidebar-top) {
                margin-left: 2rem;
              }
    
            .persona-title{
                display: flex;
                flex-direction: column;
    
                span{
                    font-size: 24px;
                    font-weight: 500;
                }
    
                .persona-header{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 20px;
                }
    
                .persona-definition-text{
                    color: var(--primary-03, $gray-neutral-7);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 180%;
                    width: 60%;
                }
    
                img{
                    width: 32px;
                    height: 32px;
                    padding: 4px;
                    flex-shrink: 0;
                }
    
                &--inactive{
                    display:none;
                }
            }
        }

        .button-container{
            margin-right: 40px;
            margin-top: 50px;

            &--inactive {
                display: none;
            }
            .btn--rounded{
                gap: 10px;
                font-size: 16px;
                font-weight: 700;
            }
            svg.cross {
                height: 25px;
                width: 25px;
                background-color: $blue-light-30;
                border-radius: 40px;
                margin-left:5px;
                margin-right: -5px;
                padding: 3px;
            }
        }

    }

}
}