@import "../../../assets/scss/variable";

.favorite-heart,
.no-click {
    svg {
        path {
            stroke: $black;
        }
        &.fill {
            fill: $blue;
            path {
                stroke: $blue;
            }
        }
        width: 20px;
        height: 17px;
    }
}

.favorite-heart {
	cursor: pointer;
}

.no-click {
	cursor: not-allowed;
}