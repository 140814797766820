@import "../../assets/scss/variable";

.search-background {
    @media (max-width: $bp-sidebar-hamburger) {
        background-color: $gray-neutral-1;
        width: 100%;
        height: 96px;
        top: 96px;
        position: relative;
    }

    .search-container {
        display: flex;
        margin-left: 10.5rem;
        margin-top: 1.5rem;
        position: fixed;
        z-index: 2;

        @media (max-width: $bp-sidebar-hamburger) {
            align-items: center;
            display: inline-flex;
            margin-left: 1.5rem;
            width: calc(100% - 3rem);
        }

        @media (min-width: calc($bp-sidebar-hamburger + 1px)) and (max-width: $bp-search-sticky) {
            width: calc(100% - 415px);
        }

        input {
            background: $white;
            border-radius: 200px;
            border: 1px solid $gray-neutral;
            box-shadow: 0px 4px 40px rgba(123, 121, 122, 0.2);
            box-sizing: border-box;

            &:focus {
                background: #E6F6F7;
                outline: 0px;
            }

            &:hover {
                background-color: #F4F4F4;
            }
        }

        button {
            display: inline-flex;
            background: $blue-dark-3;
            border-radius: 0rem 12.5rem 12.5rem 0rem;
            border: none;
            color: $white;
            font-size: 1.1rem;
            font-weight: 100;
            line-height: 1.5rem;
            padding: 0.74rem;
            position: absolute;
            right: -0.1rem;
            top: 0;
            bottom: 0;
            width: 8rem;
            box-shadow: 0px 4px 40px rgba(123, 121, 122, 0.2);

            svg {
                color: $white;
                padding-right: 0.2rem;
            }
        }

        input[type="search"] {
            border-radius: 12rem;
            border: none;
            height: 3rem;
            padding-left: 2rem;
            padding-right: 8rem;
            width: 41rem;
            font-size: 16px;

            @media all and (max-width: $bp-sidebar-hamburger){
                font-size: 13px;
            }

            @media (max-width: $bp-search-sticky) {
                width: 100%;
            }

            &:focus {
                outline: none;
            }
        }

        @media (max-width: $bp-search-button-small) {
            input[type="search"] {
                padding-right: 3rem;
            }

            button {
                width: 3rem;

                span {
                    display: none;
                }
            }
        }
    }
}