@import "../../assets/scss/variable";

.product-detail-spinner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8rem 16rem;

    &:first-child {
        margin: auto;
    }

    span {
        color: $gray-light-2;
        padding: 1em;
    }
}

.product-detail--container {
    .header {
        height: unset;

        .back-button {
            @media all and (max-width: $bp-sidebar-top) {
                margin-top: 1.5rem;
            }
        }
    }

    .product-detail-page-container {
        .product-container {
            display: flex;

            @media all and (max-width: $bp-detail-page-card-small) {
                display: unset;
                justify-content: unset;
            }
        }

        .product-detail {
            justify-content: space-between;
            margin: 3rem 0 0 9rem;
            flex-direction: row;

            @media all and (max-width: $bp-sidebar-top) {
                margin: 2rem 0 0 1.2rem;
            }

            @media (max-width: $bp-detail-page-card-small) {
                flex-direction: column;
                margin-right: 1.2rem;
            }

            @media all and (max-width: $bp-sidebar-small) and (min-width: calc($bp-sidebar-top + 1px)) {
                margin-left: 9rem;
            }

            .product-card--container {
                margin: 0 4rem;

                .detail-card {
                    width: 351px;
                    margin-bottom: 1.5rem;

                    .view-btns {
                        border: none;
                        font-weight: bold;
                        margin: 0 0 1rem 0.5rem;
                        padding: 1rem;

                        img {
                            margin: -0.1rem 0 0 0.3rem;
                        }
                    }

                    .quick-access-links {
                        display: flex;
                        flex-direction: column;
                        margin-bottom: 0.5rem;
                    }

                    @media (max-width: $bp-detail-page-card-small) {
                        width: unset;
                        min-width: 351px;
                    }

                    &.orange {
                        border-top: 8px solid $orange-light-30;
                    }
                    &.blue {
                        border-top: 8px solid $blue-light-30;
                    }
                
                    &.purple {
                        border-top: 8px solid $purple-30;
                    }
                }

                @media (max-width: $bp-detail-page-card-small) {
                    margin: 2rem auto;
                }
            }

            &--block {
                display: inline-flex;
                align-items: center;

                img {
                    height: 40px;
                    width: 40px;
                }

                .favorite {
                    margin: 5px 0 0 15px;
                }
            }

            &--container {
                margin-left: 2rem;
                display: flex;
                flex-direction: column;
                flex: 1;

                @media all and (max-width: $bp-sidebar-small) {
                    margin-left: 0;
                }

                hr {
                    margin: 0 1rem;
                    background-color: $gray-neutral-4;
                }

                .data-detail-accordion {
                    .accordion-item {
                        margin: 1.5rem 0rem;
                        box-shadow: 0px 2px 20px rgba(10, 56, 217, 0.1);
                        border: 1px solid $gray-neutral;
                        border-radius: 8px;

                        &.orange {
                            border-left: 8px solid $orange-light-30;
                        }
                        &.blue {
                            border-left: 8px solid $blue-light-30;
                        }
                    
                        &.purple {
                            border-left: 8px solid $purple-30;
                        }
                    }

                    ul {
                        list-style-type: none;
                    }
                }

                .owners-grid {
                    //display: flex;
                }

                .category-grid {
                    display: grid;
                    //grid-template-columns: repeat(auto-fit, minmax(100px, 600px));
                }

                section {
                    margin: 1.5rem 0 0 1.3rem;
                    padding-bottom: 1.5rem;
                    //display: inline-flex;

                    .container-element {
                        margin: 1.5rem 6rem 0 0;


                        @media all and (max-width: $bp-sidebar-small) {
                            margin: 1rem 2rem 0 0;
                        }

                        p {
                            color: $gray-neutral-7;
                            font-family: Effra;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 500;
                            letter-spacing: 0.02em;
                            line-height: 14px;
                            text-transform: uppercase;
                            margin-bottom: 0.3rem;
                        }
                        .description{
                            p {
                                text-transform: none;
                            }
                        }

                        .ul-column-set {
                            columns: 2;
                        }
                        li{
                            font-size: 14px;
                            padding-top: 0.3rem;
                        }

                        ul {
                            line-height: 1.5rem;
                            list-style: none;
                            padding-top: 0.3rem;
                            text-align: left;

                            li {
                                font-family: "Effra";
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 1.4rem;
                                color: $black-2;
                                padding-top: 0rem;

                                .role-name {
                                    font-size: 12px;
                                    color: $gray-neutral-7;
                                }

                                .description{
                                    height: auto;
                                    margin: 0rem;
                                }
                            }
                        }
                    }
    
                    .data-origin {
                        .report-type {
                            display: inline-flex;
                            padding-top: 0.3rem;
                            align-items: center;
    
                            svg {
                                margin-right: 0.3rem;
                                height: 15px;
                                width: 15px;
                            }
    
                            p {
                                color: $black-2;
                                font-family: Effra;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 17px;
                                text-transform: none;
                            }
                        }
                    }
                }
            }

            .description {
                margin: 1rem 0rem 1.5rem 0rem;

                &.show-more {
                    margin-bottom: 3rem;
                }

                &-container {
                    .read-button {
                        margin: 0 0rem 1.8rem 0;
                    }
                }
            }

            &--title {
                font-size: 1.5rem;
                font-weight: 500;
                color: $black-2;
            }
            
            &--card {
                align-items: center;
                border-radius: 8px;
                border: 1px solid $gray-neutral;
                box-shadow: 0px 4px 20px rgba(10, 56, 217, 0.02);
                box-sizing: border-box;
                padding: 2rem 1.3rem 1rem;
                position: relative;
                text-align: center;
                width: 351px;
    
                margin: 0 4rem 1.5rem 4rem;
    
                @media (max-width: $bp-detail-page-small) {
                    margin: 1rem;
                    padding: 2.5rem 1.3rem 2rem;
                }
    
                @media (max-width: $bp-detail-page-card-small) {
                    width: unset;
                    min-width: 351px;
                }
    
                .card-action-btns {
                    border: none;
                    margin: 1.5rem 0 0 0.5rem;
                    padding: 1rem;
                }
    
                .quick-access-links {
                    display: flex;
                    flex-direction: column;
                }
    
                @media (max-width: 360px) {
                    min-width: 250px;
                }
    
                p {
                    &:nth-child(2) {
                        color: $gray-light-2;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: normal;
                        line-height: 180%;
                    }
    
                    &.notice {
                        color: $gray-light-2;
                        font-size: 14px;
                        font-style: italic;
                    }
                }
    
                &__title {
                    font-weight: 600;
                    padding-bottom: 1rem;
                }
            }
        }

        // Tabs

        .data-traceability--tooltip {
            max-width: 250px;
        }

        .tab--panel-container {
            .data-definition {
                .datatable {
                    .dropdown-row {
                        @media all and (max-width: 1024px) {
                            .filters {
                                display: none;
                            }

                            .view-filters {
                                &.allFilters {
                                    @media all and (max-width: 1070px) {
                                        display: block;
                                    }
                                }
                            }
                        }
                    }

                    .definition {
                        font-size: 17px;
                        line-height: 21px;
                        color: $gray-neutral-7;

                        p, li {
                            font-size: 17px !important;
                            line-height: 21px !important;
                            color: hsl(207, 10%, 41%) !important;
                            padding-top: 1rem !important;

                            &:first-child {
                                padding-top: 0;
                            }
                        }
                        

                        ul {
                            padding-left: 1.5rem;
                        }
                    }
                }
            }

            .product-version {
                margin: 2rem 3rem;
                display: flex;

                @media (max-width: $bp-product-container-small) {
                    flex-direction: column;
                    margin: 2rem auto;
                }

                .version-container {
                    background-color: $white;
                    border-radius: 8px;
                    box-shadow: 0px 4px 20px rgba(10, 56, 217, 0.02);
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    height: 114px;
                    justify-content: center;
                    margin-right: 3rem;
                    text-align: center;
                    width: 330px;

                    &.orange {
                        border: 1px solid $orange;
                    }
                
                    &.blue {
                        border: 1px solid $blue-light;
                    }
                
                    &.purple {
                        border: 1px solid $purple;
                    }

                    @media (max-width: $bp-product-container-small) {
                        margin: 0.75rem auto;
                    }

                    .version-title {
                        font-size: 16px;
                        font-weight: 400;
                        color: $gray-light-2;
                        padding-bottom: 1rem;
                    }

                    .version-info {
                        font-size: 24px;
                        font-weight: 300;
                        color: $black-2;
                    }
                }
            }

            .business-use-case{
                margin: 1.5rem 1.5rem 1rem 1rem;

                .info{
                    color: #7B797A;
                font-size: 1rem;
                font-weight: normal;
                line-height: 28px;
                margin: 1rem 1rem 1rem 1rem;
                word-break: break-word;
                }
            }
            .legal-risk,
            .business-use-case,
            .product-detail-tab{
                margin: 1.5rem 1.5rem 1rem 1rem;
                
                .term-container{
                    margin: 1rem 1rem 1rem 1rem;
                    box-shadow: 0px 2px 20px rgba(10, 56, 217, 0.1);
                    border: 1px solid #F4F4F4;
                    border-radius: 8px;
                    padding: 1rem;
                    .term-title{
                        font-size: 1rem;
                        font-weight:bold;
                        color: $black-2;
                    }
                    .term-info{
                        color: #7B797A;
                    font-size: 1rem;
                    font-weight: normal;
                    line-height: 28px;
                    margin: 1rem 1rem 1rem 1rem;
                    word-break: break-word;
                    }
                }
                
            }
        }

        article {

            &.data-definition,
            &.data-traceability {
                margin: 2rem;
            }

            &.data-definition {
                display: flex;
                flex-direction: column;

                .data-definition--container {
                    display: inline-flex;
                    justify-content: space-between;
                }

                .result {
                    margin-bottom: 1.5rem;
                    p {
                        color: $gray-neutral-7;
                        font-size: 18px;
                        font-weight: 400;
                    }
                }

                .search-global-filter {
                    position: absolute;
                    right: 184px;
                    display: flex;
                    flex-direction: row-reverse;
                    justify-content: flex-end;
                    background: $white;
                    border-radius: 200px;
                    border: 1px solid $gray-neutral;
                    box-shadow: 0px 2px 20px rgba(10, 56, 217, 0.1);
                    box-sizing: border-box;
                    margin: -0.9rem 1rem 0 0;
                    padding-right: 1rem;
                    width: 300px;

                    @media all and (max-width: 1024px) {
                        right: auto;
                    }

                    &.search-filter {
                        @media (max-width: $bp-table-hide-filters) {
                            display: none;
                        }
                    }

                    &.search-only {
                        @media (max-width: 545px) {
                            width: calc(100vw - 245px);
                        }
                    }

                    input {
                        display: flex;
                        font-size: 0.7rem;
                        font-weight: 400;
                        line-height: 17px;
                        color: $black-2;
                        padding: 8px 7px 8px 0px;
                        border: none;
                        outline: none;
                        width: 100%;
                        width: -moz-available;
                        width: -webkit-fill-available;
                        width: stretch;
                    }

                    button {
                        display: inherit;
                        border: none;
                        background: none;

                        span {
                            display: none;
                        }

                        .icon {
                            padding: 9px 7px 9px 10px;
                            color: $blue;
                        }
                    }
                }

                .datatable {
                    margin-top: -3.5rem;

                    .dropdown-row {
                        margin: 0;

                        @media (max-width: $bp-product-container-medium) {
                            justify-content: flex-end;
                            width: 100%;
                        }
                    }

                    table tbody:before {
                        line-height: 0;
                    }

                    thead {
                        display: none;
                    }

                    tr {
                        justify-content: space-between;
                        height: auto;
                        position: relative;

                        td {
                            &:first-child {
                                align-items: flex-start;
                                width: 100%;

                                .title {
                                    color: $black-2;
                                    font-size: 18px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: 21px;
                                    padding: 1rem 0 0 1rem;
                                }

                                .definition {
                                    color: $gray-light-2;
                                    font-size: 18px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 180%;
                                    margin: 0.5rem 7rem 0 0;
                                    padding: 0 1rem 0 1rem;

                                    .description-container .description {
                                        height: auto;
                                    }

                                    @media all and (max-width: $bp-product-container-small) {
                                        margin-bottom: 1rem
                                    }

                                    p {
                                        padding-top: 1rem;

                                        &:first-child {
                                            padding-top: 0;
                                        }
                                    }

                                    ul {
                                        padding-left: 1.5rem;
                                    }
                                }

                                section.tab {
                                    margin-left: 0px;
                                    width:100%;
                                }
                
                                section {
                                    .tab {
                                        &--container{
                                            height: 50px;

                                            &-inactive{
                                                display: none;
                                            }

                                            @media all and (max-width:$bp-sidebar-hamburger){
                                                height: auto;
                                            }
                                            .all-tab--header-container{
                                                @media all and (max-width:$bp-sidebar-hamburger){
                                                    display:none;
                                                } 
                                            }
                                            .grouped-terms{
                                                display: flex;
                                                position: absolute;
                                                gap: 4px;
                                                align-items: flex-start;
                                                padding-right: 8px;
                                                right: 0px;
                
                                                @media all and (max-width: $bp-sidebar-hamburger){
                                                    display: none;
                                                }
                
                                                .dropbtn{
                                                    height: 40px;
                                                    width: auto;
                                                    display: flex;
                                                    align-items: center;
                                                    border-radius: 4px;
                                                    border: 1px solid var(--primary-04, $gray-neutral-4);
                                                    background: var(--neutral-white, $white);
                                                    padding: 8px 16px;
                                                    gap: 16px;
                                                    margin-right: 0.5rem;
                                                    box-shadow: 0px 2px 20px 0px rgba(48, 99, 197, 0.10);
                
                                                    .svg{
                                                        width: 16px;
                                                        height: 16px;
                                                        font-weight: bold;
                                                    }
                
                                                    .circle {
                                                        background-color: $blue-light-30;
                                                        border-radius: 200px;
                                                        color: $blue;
                                                        height: 32px;
                                                        width: 32px;
                                                        margin-left: 4px;
                                        
                                                        &:active {
                                                            background-color: $white;
                                                            color: $white;
                                        
                                                            svg {
                                                                color: $blue;
                                                            }
                                                        }
                                        
                                                        &:hover {
                                                            background-color: $blue-light-20;
                                                            transition: 0.2s ease-in-out;
                                                        }
                
                                                        svg {
                                                            padding: 0.3rem;
                                                        }
                                        
                                                    }
                                                }
                                                .dropdown-content-show{
                                                    width: auto;
                                                    font-weight: bold;
                                                    box-shadow: 0px 2px 20px 0px rgba(48, 99, 197, 0.10);
                                                    border: 1px solid var(--primary-04, #999);
                                                    margin-right: 0.85rem;
                                                } 
                                                
                                                .btn {
                                                    font-style: normal;
                                                    font-weight: 500;
                                                    font-size: 12px;
                                                    line-height: 10px;
                                                    display: flex;
                                                    letter-spacing: 0.02em;
                                                    color: $gray-neutral-7;
                                                    gap: 4px;
                                                    background: $white;
                                                    margin-right: 0.5rem;
                
                                                    .diamonds-four{
                                                        
                                                        svg{
                                                            width: 16px;
                                                            height: 16px;
                                                        }
                                                    }
                
                                                    .circle {
                                                        background-color: $blue-light-30;
                                                        border-radius: 200px;
                                                        color: $blue;
                                                        height: 32px;
                                                        width: 32px;
                                                        margin-left: 4px;
                                        
                                                        &:active {
                                                            background-color: $white;
                                                            color: $white;
                                        
                                                            svg {
                                                                color: $blue;
                                                            }
                                                        }
                                        
                                                        &:hover {
                                                            background-color: $blue-light-20;
                                                            transition: 0.2s ease-in-out;
                                                        }
                
                                                        svg {
                                                            padding: 0.3rem;
                                                        }
                                        
                                                    }
                                                }
                                            }
                                        }
                                        &--inactive {
                                            display: none;
                                        }
                                        &--holder {
                                            margin-top:1rem;
                                            border-bottom: unset;
                
                                            button {
                                                display: inline-flex;
                                                align-items: center;
                
                                                .default-caret {
                                                    padding-bottom: unset;
                                                    padding-top: 0.3rem;
                                                }
                                            }
                                        }
                
                                        &--panel {
                                            .synonym-items {
                                                padding: 8px 24px;
                                                overflow: auto;
                                                max-height: 340px;
                                                display: flex;
                                                flex-wrap: wrap;
                                                background: $green-5;
                
                                                table{
                                                    margin-left: 0rem;
                                                    min-width:100%;
                                                }
                                                @media all and (max-width: $bp-sidebar-hamburger){
                                                    display: none;
                                                }
                                            }
                                        }
                
                                        &--panel-container {
                                            height: auto;
                                            &.inactive{
                                                display: none;
                                            }
                                        }
                                    }
                                } 
                            }

                            flex-direction: column;

                            .tags {
                                align-items: flex-end;
                                display: flex;
                                flex-direction: column;
                                position: absolute;
                                right: 0;
                                top: 0;

                                @media all and (max-width: $bp-product-container-small) {
                                    top: unset;
                                    right: unset;
                                    left: 2rem;
                                    margin-bottom: 1rem
                                }

                                .chip {
                                    border-radius: 200px;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: 17px;
                                    margin: 1rem 1rem 0.5rem 0;
                                    padding: 4px 12px;
                                    color: $white;

                                    &.chip--purple {
                                        background: $purple;
                                    }

                                    &.chip--blue {
                                        background: $blue-light;
                                    }

                                    &.chip--green {
                                        background: $green;
                                    }

                                    &.chip--yellow {
                                        background: $orange;
                                    }

                                    &.chip--grey {
                                        background: $gray-light-2;
                                    }
                                }

                                .type {
                                    color: $gray-neutral-7;
                                    font-size: 16px;
                                    font-style: italic;
                                    font-weight: 400;
                                    line-height: 180%;
                                    margin-right: 1rem;
                                    text-align: right;
                                }
                            }
                        }
                    }

                    table {
                        margin: 0;
                        min-width: calc(100% - -0.5rem);

                        @media (min-width: calc($bp-product-container-small + 1px)) and (max-width: $bp-product-container-medium) {
                            min-width: calc(100% - 0.5rem);
                        }

                        thead {
                            display: none;

                            tr {
                                justify-content: flex-start;

                                th {
                                    margin-right: 5rem;

                                    &:first-child {
                                        margin-left: 1.5rem;
                                        padding-right: 1rem;

                                        @media (min-width: calc($bp-product-container-small + 1px)) and (max-width: $bp-product-container-medium) {
                                            margin-right: -2rem;
                                        }
                                    }

                                    &:nth-child(2) {
                                        @media (min-width: calc($bp-product-container-small + 1px)) and (max-width: $bp-product-container-medium) {
                                            margin-right: -2rem;
                                        }
                                    }

                                    &:nth-child(3) {
                                        @media (min-width: calc($bp-product-container-small + 1px)) and (max-width: $bp-product-container-medium) {
                                            margin-right: 1rem;
                                        }
                                    }
                                }
                            }
                        }

                        tbody {
                            tr {
                                font-size: 1.12rem;
                                flex-direction: column;
                                padding: 1rem;
                                align-items: flex-start;
                                box-shadow: 0px 2px 20px rgba(10, 56, 217, 0.1);

                                td {
                                    &:first-child {
                                        font-weight: 500;
                                        color: $black-2;
                                        min-width: 319px;
                                        padding-right: 1rem;
                                        overflow-wrap: anywhere;

                                        @media (min-width: calc($bp-product-container-small + 1px)) and (max-width: $bp-product-container-medium) {
                                            min-width: 200px;
                                        }

                                        @media (max-width: $bp-product-container-small) {
                                            min-width: 100%;
                                            margin-left: 0;
                                            color: $black-2;
                                        }
                                    }

                                    &:nth-child(2) {
                                        min-width: 243px;
                                        font-weight: 400;

                                        @media (min-width: calc($bp-product-container-small + 1px)) {
                                            align-items: flex-start;
                                        }

                                        @media (min-width: calc($bp-product-container-small + 1px)) and (max-width: $bp-product-container-medium) {
                                            min-width: 145px;
                                        }

                                        @media (max-width: $bp-product-container-small) {
                                            font-size: 14px;
                                            font-style: normal;
                                            font-weight: 500;
                                            line-height: 17px;
                                            margin: 1rem 1rem 0rem -0.5rem;
                                            min-width: auto;
                                            order: 1;
                                            padding: 4px 12px;
                                        }
                                    }

                                    &:last-child {
                                        font-weight: 400;

                                        @media (max-width: $bp-product-container-small) {
                                            line-height: 180%;
                                            margin-top: 0.5rem;
                                            order: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .pagination {
                        margin: 1rem 0 1rem 0;

                        .pagination--numbers {
                            strong {
                                font-weight: 500;
                                font-size: 1rem;
                                line-height: 1.18rem;
                            }
                        }

                        &--right {
                            span {
                                margin-right: 0rem;
                            }
                        }
                    }
                }
            }

            &.data-traceability {
                align-items: flex-start;
                display: flex;

                @media (max-width: $bp-product-container-medium) {
                    flex-direction: column;
                    align-items: stretch;
                }
            }

            .data-traceability {

                &--source {
                    margin-right: 5rem;
                }

                &--source,
                &--targets,
                &--tables {
                    box-sizing: border-box;
                    border: 1px solid $gray-neutral;
                    box-shadow: 0px 2px 20px rgba(10, 56, 217, 0.1);
                    border-radius: 8px;
                    padding-bottom: 0.5rem;
                    min-width: 440px;
                    max-width:60%;

                    @media all and (max-width: $bp-sidebar-hamburger) {
                        min-width: unset;
                    }
                    @media (max-width: $bp-product-container-medium) {
                        margin: 0 0 1.5rem 0;
                    }

                    .subtitle {
                        font-size: 18px;
                        border-bottom: 1px solid $gray-neutral-3;
                        padding-bottom: 0.7rem;
                        margin-bottom: 1.5rem;
                        background-color: $blue-dark-3;
                        color: $white;
                        padding: 1rem;
                        border-radius: 8px 8px 0 0;

                        @media (max-width: $bp-product-container-medium) {
                            width: auto;
                        }
                    }

                    .card {
                        background: $white;
                        border-radius: 8px;
                        border: 1px solid $gray-neutral;
                        box-shadow: 0px 2px 20px rgba(10, 56, 217, 0.1);
                        box-sizing: border-box;
                        display: flex;
                        flex-direction: column;
                        margin: 0 1rem 0.5rem 1rem;

                        .card-header {
                            align-items: center;
                            display: inline-flex;
                            min-height: 49px;
                            justify-content: space-between;
                            margin-right: 0.7rem;

                            img {
                                height: 1rem;
                                width: 1rem;
                            }

                            .card-text {
                                display: inline-flex;
                                align-items: center;
                                width: 75%;

                                .btn--plain-underline span {
                                    font-size: 14px;
                                    font-weight: 500;
                                    line-height: 1rem;
                                    text-transform: none;
                                    letter-spacing: normal;
                                    text-decoration: underline $blue 1px;
                                    text-underline-offset: 3px;
                                    overflow-wrap: break-word;
                                    text-align:left;
                                }

                                .btn--plain-underline span:after {
                                    color:transparent;
                                }

                                span{
                                    line-height: 1rem;
                                    font-size:14px;
                                    letter-spacing: normal;
                                    position:relative;
                                    overflow-wrap: break-word;
                                }
                            }

                            img {
                                padding-left: 10px;
                            }

                            p {
                                align-items: center;
                                color: $black;
                                font-size: 16px;
                                font-weight: 500;
                                line-height: 1rem;
                                overflow: hidden;
                                padding-left: 1rem;
                                position: relative;
                                text-overflow: ellipsis;
                                display: inline-block;
                            }

                            .chip {
                                border-radius: 200px;
                                font-size: 13px;
                                font-weight: 500;
                                padding: 4px 8px;
                                color: $white;
                                white-space: nowrap;

                                @media all and (min-width:380px) and (max-width:518px){
                                    font-size: 8px;
                                }

                                @media all and (max-width: 380px){
                                    font-size:6px;
                                }

                                &--orange {
                                    background-color: $orange;
                                }

                                &--blue {
                                    background-color: $blue-light;
                                }

                                &--green {
                                    background-color: $green;
                                }

                                &--purple {
                                    background-color: $blue;
                                }
                            }
                        }
                        .card-description{
                            font-size: 14px;
                            display: inline-flex;
                            align-items: center;
                            font-weight: 400;
                            border-radius: 12px;
                            
                            p{
                                padding: 0px 5px;
                                margin: 0px 0.5rem 0.5rem 0.7rem;
                            }
                            .description-container {
                                .read-button{
                                    margin:0 1.8rem 1.4rem 0;
                                }

                                .description{
                                    height: auto;
                                }
                            }

                            span{
                                font-style:italic;
                                color: $black-2;
                            }
                        }

                        .card-subtitle {
                            color: $gray-light-2;
                            display: flex;
                            font-size: 14px;
                            font-weight: 400;
                            padding-left: 1rem;
                            margin-bottom: 1rem;
                        }
                    }

                    .accordion-container {
                        .accordion-item {
                            .item-select-default {
                                margin-left: 1rem;
                            }
                        }
                    }
                }
            }
        }
    }
}
.drawer-container {
    #DSAPI-Model-drawer{
        width: 36vw;
    }
}