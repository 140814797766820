@import "../../assets/scss/variable";

.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 22;
    background: rgba(0, 0, 0, 0.2);
}

.modal-main {
    position: fixed;
    background-color: $white;
    width: 788px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 100vh;
    overflow-x: hidden;
    border-radius: 5px;

    @media all and (max-width: 1036px) and (min-width: $bp-modal-shrink-width) {
        width: 730px;
    }
    @media all and (max-width: $bp-modal-shrink-width) and (min-width: calc($bp-sidebar-hamburger + 1px)) {
        width: 700px;
    }
    @media (max-width: calc($bp-sidebar-hamburger + 1px)) {
        width: 100%;
        left: 0;
        transform: none;
    }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.modal-main::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.modal-main {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.close-icon {
    background-color: unset;
    border-radius: 0.3rem;
    border: none;
    color: $black-2;
    cursor: pointer;
    height: 1.8rem;
    position: absolute;
    right: 1.2rem;
    top: 1.2rem;
    transition: 0.3s ease all;
    width: 1.8rem;

    &:hover {
        color: #313030;
    }
}

.modal-close {
    cursor: pointer;
    float: right;
    padding-top: 30px;
}

.show {
    display: block;
}

.sm {
    min-height: 351px;

    @media (max-width: calc($bp-sidebar-hamburger + 1px)) {
        height: calc(100vh - 150px);
    }
}

.x-sm {
    max-height: 350px;

    @media (max-width: calc($bp-sidebar-hamburger + 1px)) {
        height: calc(100vh - 150px);
    }
}

.md {
    height: 751px;

    @media (max-width: calc($bp-sidebar-hamburger + 1px)) {
        height: calc( 100vh - 90px);
        top: 96px;
    }
}

.hide {
    display: none;
}