@import "../../assets/scss/variable";

.popup-container {
    position: fixed;
    top: 0;
    left: 135px;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgba(0, 0, 0, 0.2);

    .hide {
        display: none;
    }

    .show {
        display: block;
    }

    @media (max-width: calc($bp-sidebar-small)) {
        left: 125px;
    }

    @media (max-width: calc($bp-sidebar-top)) {
        left: 0;
        top: 150px;
    }

    @media (max-width: calc($bp-sidebar-hamburger)) {
        display: none;
    }

}

.popup-main {
    background-color: $green-10;
    position: absolute;
    width: 430px;
    height: 242px;
    left: 150px;
    border: 1px solid $green-1;
    border-radius: 8px;
    z-index: 2;

    .hide {
        display: none;
    }

    .show {
        display: block;
    }

    @media (max-width: calc($bp-sidebar-top + 1px)) {
        top: 115%;
        left: -115px;
    }

    .popup-content {
        margin: 1.5rem;
        .popup-header {
            color: $black-2;
            font-weight: 500;
            font-size: 16px;
            margin-bottom: 0.5rem;
        }

        p {
            color: $gray-neutral-7;
            font-weight: 400;
            font-size: 16px;
            margin-bottom: 0.5rem;
            line-height: 19px;
        }

        .popup-btns {
            bottom: 0;
            left: 1.5rem;
            right: 1.5rem;
            position: absolute;
            margin-bottom: 1.5rem;
            display: flex;
            justify-content: space-between;
        }
    }

    @media (max-width: calc($bp-sidebar-hamburger)) {
        display: none;
    }

}

.popup-main:before {
    left: -9px;
    top: 45%;
    height: 20px;
    width: 20px;
    box-shadow: -1px 1px 0px 0px $green-1;
    border-right: none;
    border-top: none;
    content: " ";
    background: $green-10;
    position: absolute;
    pointer-events: none;
    transform: rotate(45deg);

    @media (max-width: calc($bp-sidebar-top + 1px)) {
        left: 215px;
        top: -10px;
        transform: rotate(135deg);
    }
}