@import "../../assets/scss/variable";
@import "animate.css";

section {
    &.tab {
        margin-left: 8.5rem;

        @media all and (max-width: $bp-sidebar-small) and (min-width: calc($bp-sidebar-top + 1px)) {
            margin-left: 7.5rem;
        }

        @media all and (max-width: $bp-sidebar-top) {
            margin-left: 0rem;
        }
    }

    .tab {
        &--container {
            display: flex;
        }

        &--header-container {
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        &--panel {
            flex-direction: column;
            font-size: 2rem;
            height: 100%;
            justify-content: center;
            width: 100%;

            &.animate__animated.animate__fadeIn {
                --animation-duration: 600ms;
                -webkit-animation-duration: 600ms;
                animation-duration: 600ms;
            }
        }

        &--panel-container {
            height: 80vh;
            &.inactive {
                background-color: $green-5;
            }
        }

        &--holder {
            display: flex;
            margin-top: 3rem;
            min-width: 100%;
            border-bottom: 1px solid $gray-neutral-4;

            @media (max-width: $bp-detail-page-card-small) {
                margin-top: 0;
            }

            @media (max-width: $bp-detail-page-tabs-wrap) {
                flex-wrap: wrap;
                justify-content: space-around;
            }

            button {
                background-color: $gray-neutral;
                border-radius: 8px 8px 0px 0px;
                border: none;
                color: $black;
                min-width: 150px;
                cursor: pointer;
                font-family: "Effra";
                font-size: 1rem;
                font-weight: 500;
                letter-spacing: 0.02em;
                margin-left: 2rem;
                padding: 0 1rem;
                text-transform: uppercase;
                height: 35px;

                .default-caret {
                    margin-left: 0.3rem;
                    color: $blue;
                    cursor: pointer;
                }

                @media (max-width: $bp-product-container-small) {
                    margin-left: 1rem;
                    width: auto;
                }

                &.active {
                    background-color: $green-1;
                    color: $white;
                    transition: 0.1s ease-in;
                
                    .default-caret {
                        color: $white;
                    }

                }
            }
        }

        &--inactive {
            opacity: 0.65;
        }
    }
}