@import "./assets/scss/variable";

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

button {
  cursor: pointer;
}

.content--container {
  .header {
    align-content: flex-start;
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    height: 165px;
    justify-content: flex-start;
    margin: 8rem 0 0 0;
    overflow: hidden;
    width: 100%;

    @media all and (max-width: $bp-sidebar-top) {
      margin: 10rem 0 0 0;
    }

    @media all and (max-width: $bp-sidebar-hamburger) {
      margin: 12rem 0 0 0;
      height: 170px;
    }

    &--title {
      margin-left: 11rem;

      @media all and (max-width: $bp-sidebar-small) and (min-width: calc($bp-sidebar-top + 1px)) {
        margin-left: 9rem;
      }
      @media (max-width: $bp-sidebar-top) {
        margin-left: 2rem;
      }

      h1 {
        color: $black-2;
        font-family: "Effra";
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 2.8rem;
        margin-bottom: 0;
        margin-top: 2rem;

        @media all and (max-width: $bp-sidebar-top) {
            margin-top: 1rem;
          }

        @media all and (max-width: $bp-sidebar-hamburger) {
          margin-top: 1.5rem;
        }
      }

      h2 {
        align-items: center;
        color: $gray-neutral-7;
        font-size: 24px;
        font-style: normal;
        font-weight: 300;
        line-height: 1.8rem;
        margin-top: 0;

        @media all and (max-width: $bp-sidebar-hamburger) {
          font-size: 1.2rem;
        }
      }
    }
  }

  .bar-blue {
    background: $green-1;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.02);
    height: 4px;
    position: absolute;
    top: 290px;
    width: 100%;

    @media (max-width: $bp-sidebar-hamburger) {
      top: 400px;
    }

    @media (min-width: calc($bp-sidebar-hamburger + 1px)) and (max-width: $bp-sidebar-top) {
      top: 343px;
    }
  }
}

.circle {
  border-radius: 200px;
  display: flex;
  justify-content: center;
  align-items: center;

  &--blue {
    background-color: $blue-light-10;
    color: $blue;
    height: 32px;
    margin-right: 0.5rem;
    width: 32px;

    &:active {
      background-color: $white  !important;
      color: $white;

      svg {
        color: $blue;
      }
    }

    &:hover {
      background-color: $blue-light-20;
      transition: 0.2s ease-in-out;
    }

    svg {
      padding: 0.3rem;
    }
  }

  &--red {
    align-items: center;
    background-color: $red-light-10;
    color: $red-1;
    display: inline-flex;
    height: 23px;
    justify-content: center;
    margin-left: 0.5rem;
    width: 23px;

    &:active {
      background-color: $red  !important;
      color: $red;

      svg {
        color: $white;
      }
    }

    &:hover {
      background-color: $red-light-20;
      transition: 0.2s ease-in-out;
    }
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

main {
  height: 100%;
  position: relative;
  width: 100%;
}
