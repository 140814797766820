@import "../../assets//scss/variable";

.search-page {
    button.btn-record.btn--blue-light {
        position: absolute;
        top: 14rem;
        margin-left: 10rem;
        
        &.btn--blue-light {
            padding: 11px 25px;
        }

        @media (max-width: $bp-sidebar-small) {
            margin-left: 7.5rem;
        }

        @media (max-width: $bp-sidebar-top) {
            margin-left: 1.5rem;
            top: 18rem;
        }

        @media (max-width: $bp-sidebar-hamburger) {
            margin-left: 1.5rem;
            top: 19rem;
        }
        
    }
}

#record-page-datatable {
    table {
        tbody {
            tr {
                cursor: pointer;
                .access-origin-separate {
                    div {
                        display: inline-flex;
                    }

                    @media (max-width: $bp-search-row-condense) {
                        display: block;
                    }
                }
                td {
                    color: $black-2;
                    &:first-child {
                        img {
                            visibility: visible;
                        }
                    }
                    &:nth-child(2) {
                        display: none;
                    }
                    &:nth-child(6) {
                        margin-right: 2rem;

                        @media (max-width: $bp-search-row-condense) {
                            display: block;
                        }
                    }
                    &:nth-child(7) {
                        align-items: flex-start;
                        color: $black-2;
                        display: inline-flex;
                        flex-direction: column;
                        font-family: "Effra";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 19px;
                        margin: 0 1rem 1rem 0.8rem;
                        min-width: 200px;
                        position: inherit;
                        top: 0;

                        svg {
                            margin-top: 0.1rem;
                            margin-right: 0.5rem;
                        }
                    }
                }
            }
        }
    }
}

.form {
    form {
        margin: 1.5rem 1.5rem 6.5rem 1.5rem;

        input[type="text"] {
            &:disabled {
                opacity: 0.6;
            }
        }

        label {
            display: inline-flex;
            padding-right: 1rem;
        }

        p {
            &.input-error {
                color: $red;
                margin: 1rem 0;
            }
        }

        .dropdownmenu {
            button{
                top:0.5rem;
            }
        }

        .permitted-groups {
            display: flex;
            flex-direction: column;
            
            button {
                width: 20%;
                align-self: flex-start;
                padding: 1rem 3rem;
            }
        }
    }
}
.modal-footer {
    button {
        margin: 2rem 1rem;
        width: 200px !important;
    }
}
