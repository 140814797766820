@import "../../assets/scss/variable";

.search-page {
    .results-datatable {
        .dropdown-row {
            .filters {
                #legacyBrand,
                #primaryOwne {
                    display: none;
                }

                @media all and (max-width: 765px) {

                    #domain {
                        display: none;
                    }
                }

                @media all and (max-width: 1272px) {
                    #hasAccess,
                    #legacyBrand,
                    #primaryOwne {
                        display: none;
                    }
                }

                @media all and (max-width: 1035px) {
                    #reportType {
                        display: none;
                    }
                }

                @media all and (max-width: 893px) {
                    #businessSeg {
                        display: none;
                    }
                }
            }

            .view-filters {
                &.allFilters {
                    display: block;

                    .sort-dropdown-content {
                        .filters--container {
                            height: calc(100% - 59px - 3.75rem);
                        }
                    }
                }
            }
        }

        .cell--title {
            display: none;
        }

        table {

            thead,
            tbody {
                tr {
                    display: none;
                }

                .search-favorite {
                    margin-right: 1rem;
                }

                .product-img {
                    path {
                        stroke: $black;
                    }
                    margin-right: 1rem;
                    margin-bottom: 0.1rem;

                    &--disabled {
                        margin-right: 1rem;
                        margin-bottom: 0.1rem;
                        cursor: not-allowed;
                    }

                    @media (max-width: $bp-search-row-condense) {
                        display: none;
                    }
                }

                .actions-no-launch {
                    @media (max-width: $bp-search-row-condense) {
                        display: none;
                    }
                }

                .actions-launch {
                    display: none;

                    @media (max-width: $bp-search-row-condense) {
                        display: block;
                        position: absolute;
                        top: 7rem;
                        margin-right: 0.5rem;
                    }

                    @media (max-width: 470px) {
                        top: 11rem;
                    }
                }

                .access-origin-separate {
                    align-items: center;

                    div {
                        display: inline-flex;
                    }

                    svg {
                        margin-top: 0.1rem;
                        margin-right: 0.5rem;
                    }
                }
            }

            tbody {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: stretch;

                &:before {
                    display: none;
                }

                tr {
                    background: $white;
                    border-radius: 8px;
                    border: 1px solid $gray-neutral;
                    box-shadow: 0px 2px 20px rgba(10, 56, 217, 0.1);
                    display: inline-block;
                    margin-bottom: 1rem !important;
                    padding: 1rem 1.5rem 0 1.5rem;
                    position: relative;
                    width: calc(100vw - 11rem);

                    &.orange {
                        border-left: 8px solid $orange-light-30;

                        &:hover {
                            box-shadow:
                                0px 2px 20px rgba(10, 56, 217, 0.1),
                                0 0 0 1px $orange;
                        }
                        &:active {
                            background: $orange-light-20;
                        }
                    }

                    &.blue {
                        border-left: 8px solid $blue-light-30;

                        &:hover {
                            box-shadow:
                                0px 2px 20px rgba(10, 56, 217, 0.1),
                                0 0 0 1px $blue-light;
                        }
                        &:active {
                            background: $blue-light-20;
                        }
                    }

                    &.purple {
                        border-left: 8px solid $purple-30;

                        &:hover {
                            box-shadow:
                                0px 2px 20px rgba(10, 56, 217, 0.1),
                                0 0 0 1px $purple;
                        }
                        &:active {
                            background: $purple-20;
                        }
                    }

                    td {
                        width: auto;
                        height: 40px;

                        .segmentData,
                        .domainData {
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                            max-width: 13rem;
                        }

                        .cell--title {
                            display: block;
                            font-family: "Effra";
                            font-style: normal;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 14px;
                            color: $gray-neutral-7;
                            text-transform: uppercase;
                            white-space: nowrap;
                            margin-bottom: 0.2rem;
                        }

                        &.title-column {
                            width: auto;
                            max-width: calc(100% - 7rem);
                            margin-bottom: 1.5rem;

                            button {
                                background: none;
                                color: $black-2;
                                cursor: pointer;
                                font-family: "Effra";
                                font-size: 1rem;
                                font-weight: 500;
                                overflow: hidden;
                                position: relative;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }

                            @media (max-width: $bp-search-row-condense) {
                                padding-right: 1.5rem;
                            }

                            button {
                                align-items: center;
                                color: $black-2;
                                font-size: 1rem;
                                font-weight: 500;
                                width: auto;
                                text-align: start;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: normal;
                                margin-left: 3rem;

                                @media (max-width: $bp-search-row-condense) {
                                    text-align: start;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    overflow-wrap: anywhere;
                                }
                            }

                            img.arrow-separator {
                                display: block;
                                visibility: visible;
                                height: 10px;
                                width: 10px;
                                transform: rotateZ(90deg);
                                margin-left: 0.3rem;
                            }
                        }

                        &.type-icon-column {
                            visibility: hidden;
                            position: absolute;
                            top: 1rem;

                            img {
                                visibility: visible;
                                height: 40px;
                                width: 40px;
                            }
                        }

                        &.data-column {
                            width: calc(17% - 2rem);
                            max-width: 25rem;
                            display: inline-flex;
                            flex-direction: column;
                            align-items: flex-start;
                            margin-right: 1rem;
                            font-family: "Effra";
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 19px;
                            color: $black-2;
                            margin-bottom: 1rem;
                            min-width: 200px;

                            img {
                                height: 17px;
                                margin-right: 0;
                                padding-right: 0.5rem;
                            }
                        }

                        &.access-origin-column {
                            display: none;
                        }

                        &.actions-column {
                            position: absolute;
                            top: 1rem;
                            right: 0.75rem;
                        }
                    }
                }
            }
        }

        @media all and (max-width: $bp-table-small) {
            table {
                tbody {
                    tr {
                        min-width: 339px;
                    }
                }
            }
        }

        @media all and (max-width: $bp-sidebar-top) {
            table {
                tbody {
                    tr {
                        width: calc(100vw - 2rem);
                    }
                }
            }
        }

        @media all and (max-width: $bp-search-row-condense) {
            table {
                tbody {
                    tr {
                        td {
                            &.title-column {
                                max-width: calc(100% - 3rem);
                            }
                            &.segment-column,
                            &.owner-column {
                                min-width: 150px;
                            }

                            &.access-origin-column {
                                display: block;
                            }
                            &.domain-column,
                            &.origin-column,
                            &.access-column {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }

    .back-button {
        margin-top: 8rem;

        @media all and (max-width: $bp-sidebar-top) {
            margin-top: 11.5rem;
        }

        @media all and (max-width: $bp-sidebar-hamburger) {
            margin-top: 13.5rem;
        }
    }
}