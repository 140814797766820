@import "../../assets/scss/variable";

.grouped-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 3.5rem 2rem 0 10rem;

    div {
        flex: 1 1 32%;
    }

    @media all and (max-width: $bp-sidebar-top) {
        margin-left: 1.5rem;
    }
}