@import "../../assets/scss/variable";

.faq-section-card {
    background: $white;
    border-radius: 8px;
    border: 1px solid $gray-neutral;
    box-shadow: 0px 2px 20px rgba(10, 56, 217, 0.1);
    box-sizing: border-box;
    display: block;
    min-height: 303px;
    position: relative;
    max-width: 900px;

    hr {
        background-color: $gray-neutral;
        border: 0;
        height: 1px;
        size: 1px;
        width: 100%;
    }

    .link {
        text-decoration: none;
    }

    &--header {
        position: relative;
        justify-content: space-between;
        align-items: center;
        display: flex;
        background-color: $green-1;
        border-radius: 8px 8px 0 0;
    }

    &--title,
    &--sub {
        display: inline-flex;
        align-items: center;
        color: $white;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.02em;
        line-height: 19px;
        margin-left: 1.5rem;
        padding: 1.5rem 0;
        text-transform: uppercase;
        float: left;
        img {
            width: 24px;
            height: 24px;
            margin-right: 0.5rem;
        }
    }

    &--sub {
        font-weight: 400;
        float: right;
        margin-right: 1.5rem;
        line-height: 21.6px;
    }

    &--items {
        display: block;
        height: 100%;
        padding-bottom: 1rem;
    }

    .card-item {
        background: $white;
        border-bottom: 1px solid $gray-neutral;
        display: inline-flex;
        align-items: center;
        width: 100%;
        height: 56px;
        padding-left: 0.75rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        position: relative;

        .item-header {
            display: inline-flex;
            align-items: center;
            width: 100%;
            .item-name {
                text-overflow: ellipsis;
                max-width: 13.5rem;
                overflow: hidden;     
                white-space: nowrap;
            }
        
            img.arrow-separator {
                height: 10px;
                width: 10px;
                transform: rotateZ(90deg);
                margin-left: 0.3rem;
            }
        }

        .item-actions {
            display: inline-flex;
            margin-right: 1.5rem;
            img {
                margin-right: 1rem;
            }
        }
    }
}