@import "../../assets/scss/variable";

.browse-content {
    .browse-card,
    .browse-card-wide {
        margin: 0.75rem;

        @media all and (max-width: $bp-browse-narrow) {
            margin-right: 0;
        }

        hr {
            background-color: $gray-neutral;
            border: 0;
            height: 1px;
            margin-bottom: 1.5rem;
            size: 1px;
            width: 100%;
        }

        &--header-box {
            border-radius: 8px 8px 0 0;
            background-color: $green-1;
            margin-bottom: 1.5rem;
        }

        .link {
            text-decoration: none;
        }

        &--title {
            color: $white;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.02em;
            line-height: 19px;
            margin-left: 1.5rem;
            padding: 1.5rem 0;
            text-transform: uppercase;
        }

        .browse-button {
            align-items: center;
            display: inline-flex;
            font-weight: 700;
            justify-content: space-between;
            margin-bottom: 1rem;
            padding-left: 1.5rem;
            text-align: left;
            width: 50%;

            &:disabled {
                .circle {
                    background-color: $white;
                    color: $gray-light-2;
                }
            }

            .circle {
                background-color: $blue-light-30;
                border-radius: 200px;
                color: $blue;
                height: 32px;
                width: 32px;

                &:active {
                    background-color: $white  !important;
                    color: $white;

                    svg {
                        color: $blue;
                    }
                }

                &:hover {
                    background-color: $blue-light-20;
                    transition: 0.2s ease-in-out;
                }

                svg {
                    padding: 0.3rem;
                }
            }
        }
    }

    .browse-card {
        min-height: 527px;
        width: 393px;
        margin-bottom: 1.5rem;

        .browse-item {
            align-items: center;
            box-shadow: 0px 4px 20px rgba(10, 56, 217, 0.02);
            display: inline-flex;
            flex-direction: column;
            height: 180px;
            justify-content: center;
            justify-items: center;
            margin: 0 1rem 1rem 0rem;
            width: 164px;

            p {
                color: $gray-light-2;
                font-size: 18px;
                font-weight: 400;
                line-height: 21px;
                margin-top: 1.4rem;
            }

            &:hover {
                border: 1px solid $blue-light;
            }

            &:active {
                background: $blue-light-10;
                border: 1px solid $blue-light;
            }
        }
    }

    .browse-card-wide {
        height: auto;

        @media all and (max-width: $bp-browse-stacked) {
            .browse-card--children {
                .browse-card--browse-all {
                    display: block;
                }
            }
        }

        .browse-card--option {
            &.product-type {
                .browse-item {
                    width: 15vw;

                    span{
                        &.subtitle-braces{
                            display:none;
                        }
                    }

                    @media (max-width: $bp-browse-card-single) {
                        justify-content: left;
                        margin: 0.5rem 0;
                        padding: 0.55rem 0;
                        width: 90%;
                    }

                    @media (min-width: calc($bp-browse-card-small)) and (max-width: 1311px) {
                        width: 25vw;
                    }
                }
            }
        }

        .browse-item {
            align-items: center;
            box-shadow: 0px 2px 20px rgba(10, 56, 217, 0.1);
            cursor: pointer;
            display: inline-flex;
            flex-direction: column;
            flex: 1;
            height: 110px;
            justify-content: center;
            justify-items: center;
            margin: 0rem 0.25rem;
            width: 90%;

            @media (max-width: $bp-browse-card-single) {
                flex-direction: row;
                justify-content: left;
                margin: 0.5rem 0;
                padding: 0.55rem 0;
            }

            @media (max-width: $bp-sidebar-hamburger) {
                height: 80px;
                flex: unset;
            }

            img {
                margin: 0 1rem 0 1rem;
                height: 32px;
                width: 32px;
            }

            p {
                color: $black-2;
                font-size: 18px;
                font-weight: 500;
                line-height: 21px;
                text-align: center;

                &.browse-item-subtitle {
                    font-size: 12px;
                    font-weight: 400;
                    color: $gray-neutral-7;

                    @media all and (max-width:$bp-sidebar-small){
                        margin-left: 0.5rem;
                    }

                    span{

                        display:none;

                        @media all and (max-width:$bp-sidebar-small){
                            display:unset;
                        }
                    }
                }
            }

            
            
            &:hover {
                border: 1px solid $blue-light;
            }

            &:active {
                background: $blue-light-10;
                border: 1px solid $blue-light;
            }
        }

        .selected-item {
            background: $blue-light-10;
            border: 1px solid $blue-light;
        }
    }

    .browse-card,
    .browse-card-wide,
    .browse-item {
        background: $white;
        border-radius: 8px;
        border: 1px solid $gray-neutral;
        box-shadow: 0px 2px 20px rgba(10, 56, 217, 0.1);
        box-sizing: border-box;
        position: relative;

        .browse-card--children {
            align-items: center;
            flex-direction: column;
            justify-content: space-between;
            min-height: 470px;
            padding: 0rem 2.5rem 0rem 1.5rem;

            .browse-card--browse-all {
                width: 100%;
                position: inherit;
                bottom: 0;
                text-align: center;
            }
        }
    }

    .browse-card,
    .browse-card-wide {
        @media (max-width: $bp-browse-stacked) {
            position: relative;
            left: 0;
            width: auto;
            min-height: 209px;
            margin: 0 2rem 1.5rem 2rem;

            hr {
                margin-bottom: 1rem;
            }

            div.browse-card--children {
                position: relative;
                height: auto;
                min-height: auto;
                padding: 0;
                overflow: visible;
                width: 100%;

                .browse-card--links {
                    display: inline-flex;
                    width: 100%;
                    overflow-x: unset;
                    flex-wrap: wrap;
                    justify-content: space-evenly;
                }
            }
        }

        @media (min-width: calc($bp-browse-small + 1px)) and (max-width: $bp-browse-stacked) {
            div.browse-card--children {
                .browse-item {
                    img {
                        margin: 0 1rem 0 1rem;
                    }

                    p {
                        margin-top: 0;
                    }
                }

                .browse-card--browse-all {
                    bottom: auto;
                    right: 0;

                    span {
                        white-space: nowrap;

                        &.browse-all--title {
                            margin-right: 0.5rem;
                        }

                        &.browse-all--name {
                            display: none;
                        }
                    }
                }
            }
        }

        @media all and (max-width: $bp-browse-small) {
            height: auto;

            div.browse-card--children {
                .browse-card--links {
                    a {
                        .browse-item {
                            align-items: center;
                            box-shadow: 0px 4px 20px rgb(10 56 217 / 2%);
                            display: inline-flex;
                            flex-direction: column;
                            height: 180px;
                            justify-content: center;
                            justify-items: center;
                            margin-bottom: 1.5rem;
                            margin-left: 0rem;
                            min-width: 90%;
                        }
                    }
                }

                .browse-card--browse-all {

                    .browse-button {
                        width: 65vw;
                    }
                }
            }
        }
    }
}


.manage-recs,
.manage-updates {
    .browse-card-recommendation {
        height: auto;

        background: $white;
        border-radius: 8px;
        border: 1px solid $gray-neutral;
        box-shadow: 0px 2px 20px rgba(10, 56, 217, 0.1);
        box-sizing: border-box;
        position: relative;

        .browse-card--children {
            align-items: center;
            flex-direction: column;
            justify-content: space-between;
            //min-height: 470px;
            padding: 0rem 2.5rem 0rem 1.5rem;

            .browse-card--browse-all {
                width: 100%;
                position: inherit;
                bottom: 0;
                text-align: center;
            }
        }

        @media all and (max-width: $bp-browse-stacked) {
            .browse-card--children {
                .browse-card--browse-all {
                    display: block;
                }
            }
        }

        .browse-card--option {
            &.product-type {
                .browse-item {
                    width: 15vw;

                    @media (max-width: $bp-browse-card-single) {
                        justify-content: left;
                        margin: 0.5rem 0;
                        padding: 0.55rem 0;
                        width: 90%;
                    }

                    @media (min-width: calc($bp-browse-card-small)) and (max-width: 1311px) {
                        width: 25vw;
                    }
                }
            }
        }



        .browse-item {
            align-items: center;
            box-shadow: 0px 2px 20px rgba(10, 56, 217, 0.1);
            cursor: pointer;
            display: inline-flex;
            flex-direction: column;
            flex: 1;
            height: 110px;
            justify-content: center;
            justify-items: center;
            margin: 0rem 0.25rem;
            width: 90%;

            

            @media (max-width: $bp-browse-card-single) {
                flex-direction: row;
                justify-content: left;
                margin: 0.5rem 0;
                padding: 0.55rem 0;
            }

            @media (max-width: $bp-sidebar-hamburger) {
                height: 80px;
                flex: unset;
            }

            img {
                margin: 0 1rem 0 1rem;
                height: 32px;
                width: 32px;
            }

            p {
                color: $black-2;
                font-size: 18px;
                font-weight: 500;
                line-height: 21px;
                text-align: center;

                &.browse-item-subtitle {
                    font-size: 12px;
                    font-weight: 400;
                    color: $gray-neutral-7;
                }
            }

            
            
            &:hover {
                border: 1px solid $blue-light;
            }

            &:active {
                background: $blue-light-10;
                border: 1px solid $blue-light;
            }
        }

        .selected-item {
            background: $blue-light-10;
            border: 1px solid $blue-light;
        }
    }

    .browse-card {
        margin: 0.75rem;

        @media all and (max-width: $bp-browse-narrow) {
            margin-right: 0;
        }

        hr {
            background-color: $gray-neutral;
            border: 0;
            height: 1px;
            margin-bottom: 1.5rem;
            size: 1px;
            width: 100%;
        }

        &--header-box {
            border-radius: 8px 8px 0 0;
            background-color: $blue-dark-3;
            margin-bottom: 1.5rem;
        }

        .link {
            text-decoration: none;
        }

        &--title {
            color: $white;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.02em;
            line-height: 19px;
            margin-left: 1.5rem;
            padding: 1.5rem 0;
            text-transform: uppercase;
        }
    }
}