@import "./assets/scss/variable";
@import url("https://use.typekit.net/nsk3egb.css");

* {
  font-family: "Effra", sans-serif;
  margin: 0;
  padding: 0;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: linear-gradient(180deg, $white 21.04%, $gray-neutral-1 43.32%);
  font-family: "Effra", sans-serif;
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}