@import "../../assets/scss/variable";

.tutorial-pop {
    display: flex;
    box-shadow: 0px 2px 20px rgba(10, 56, 217, 0.1);
    border: 1px solid $gray-neutral;
    border-radius: 8px;
    overflow: hidden;
    background-color: $white;

    &:hover {
        box-shadow:
        0px 2px 20px rgba(10, 56, 217, 0.1),
        0 0 0 1px $green-1;
    }
    
}

.browse-image {
    float: left;
    margin-bottom: -10px;
    display: block;
}

.description-tutorial {
    float: left;
    margin-top: 40px;
    margin-bottom: -40px;
    line-height: 28px;
    margin-right: 2rem;
    margin-left: 2rem;

    p.title {
        font-weight: bold;
        font-size: 16px;
        color: $black;
    }
    p.subtitle {
        font-weight: 400;
        font-size: 16px;
        color: $gray-neutral-7;
    }
}
