$black-2: #001E2E;
$black: #000000;
$blue-dark-3: #000E5F;
$blue-dark-2: #333091;
$blue-dark: #1F4199;
$blue-light-5: #F3FBFF;
$blue-light-10: #E8F7FF;
$blue-light-20: #D1EEFF;
$blue-light-25: #CCECEF;
$blue-light-30: #BAE6FE;
$blue-light: #18AAFD;
$blue: #0A38D9;
$gray-light-2: #7B797A;
$gray-light: #E5E5E5;
$gray-neutral-1: #FAFAFA;
$gray-neutral-2: #F2F2F2;
$gray-neutral-3: #CCCCCC;
$gray-neutral-4: #999999;
$gray-neutral-5: #EDEDED;
$gray-neutral-7: #5E6972;
$gray-neutral: #F4F4F4;
$green: #429538;
$green-1: #00A0B1;
$green-30: #B3E3E8;
$green-10: #E6F6F7;
$green-5: #F2FAFB;
$orange-light-20: #F8E4CC;
$orange-light-30: #F4D7B3;
$orange: #DA7800;
$purple: #7442BA;
$purple-20: #E3D9F1;
$purple-30: #D5C6EA;
$purple-light: #F6F9FF;
$red-light-5: #FCF3F4;
$red-light-10: #FBE9EA;
$red-light-20: #F2CED3;
$red: #D72631;
$red-1: #BD0C22;
$white: #ffffff;

$bp-browse-narrow: 1460px;
$bp-browse-stacked: 1390px;
$bp-browse-card-small: 1280px;
$bp-browse-card-wrap: 868px;
$bp-browse-small: 588px;
$bp-browse-card-single: 868px;

$bp-datatable-pagination-center: 600px;

$bp-product-container-small: 710px;
$bp-product-container-medium: 1024px;
$bp-favorite-full-width: 500px;
$bp-favorite-shrink-width: 440px;

$bp-drawer-content-vertical: 800px;

$bp-modal-shrink-width: 830px;

$bp-navbar-crosses-hide: 1480px;

$bp-table-small: 710px;

$bp-search-table-condense: 1012px;
$bp-search-sticky: 1085px;
$bp-search-table-small: 965px;
$bp-search-button-small: 868px;
$bp-table-hide-filters: 710px;
$bp-search-row-condense: 600px;

$bp-sidebar-small: 1360px;
$bp-sidebar-top: 1166px;
$bp-sidebar-hamburger: 840px;

$bp-detail-page-small: 710px;
$bp-detail-page-card-small: 1020px;
$bp-detail-page-tabs-wrap: 500px;

$bp-favorites-page-toggle: 860px;