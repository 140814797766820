@import "../../assets/scss/variable";

.faq--container {
    .bar-blue {
        @media (max-width: $bp-sidebar-hamburger) {
            top: 375px;
        }
    }

    .back-button {
        @media all and (max-width: $bp-sidebar-top) {
            margin-top: 1rem;
        }
    }
    
    .faq-content {
        display: flex;
        justify-content: space-between;
        margin: 2rem 2rem 0 11rem;

        @media all and (max-width: $bp-sidebar-small) and (min-width: calc($bp-sidebar-top + 1px)) {
            margin-left: 9rem;
        }

        @media all and (max-width: $bp-sidebar-top) {
            margin: 3.5rem 2rem 0 1.5rem;
        }

        @media all and (max-width: 900px) {
            flex-direction: column;
        }

        .faq-questions-container {
            margin-top: 0.75rem;

            @media all and (max-width: 900px) {
                order: 2
            }

            .faq-section-card {
                margin-bottom: 1.5rem;

                .accordion-container {
                    margin-left: 1.5rem;
                    margin-right: 1.5rem;
                }

                .accordion-item {
                    border-bottom: 1px solid $gray-neutral-4;

                    .accordion-content {
                        padding: 0 2rem 1.5rem 1rem;
                    }

                    p {
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 32px;
                        text-align: left;
                        color: $gray-neutral-7;
                        margin: 1rem 0 0 0;
                    }

                    ol {
                        list-style-position: inside;

                        li {
                            font-size: 18px;
                            font-weight: 400;
                            line-height: 32px;
                            text-align: left;
                            color: $gray-light-2;
                            margin: 1rem 0;

                            u {
                                text-decoration: underline $gray-neutral-4;
                            }
                        }
                    }

                    ul {
                        list-style-position: inside;
                        list-style-type: disc;

                        li {
                            font-size: 18px;
                            font-weight: 400;
                            line-height: 32px;
                            color: $gray-light-2;
                        }
                    }

                }
            }
        }


        .faq-overview-container {
            position: -webkit-sticky;
            position: sticky;
            top: 130px;
            height: 100%;
            margin: 0.75rem 2rem 0 4rem;
            z-index: 1;

            @media (max-width: 1356px) {
                margin: 0.75rem 1rem 0 2rem;
            }

            @media (max-width: 900px) {
                position: unset;
                margin: 0;
            }

            @media (min-width: 900px) and (max-width: $bp-sidebar-top) {
                top: 190px;
            }

            .get-in-touch {
                @media all and (max-width: 900px) {
                    position: absolute;
                    top: 100%;
                    width: calc(100vw - 3.5rem);
                }
            }

            .detail-card {
                margin-bottom: 1.5rem;
                border-top: 8px solid $green-30;

                .form{
                    text-align: start;
                }
            }

            .quick-access-links {
                display: flex;
                flex-direction: column;
                margin-bottom: 0.5rem;
            }

            .get-in-touch-btn {
                border: none;
                font-weight: bold;
                margin: 0 0 1rem 0.5rem;
                padding: 1rem;
            }

            .add-mktpl-btn {
                border: none;
                margin: 0 0 1rem 0.5rem;
                background-color: $blue-light-10;
                color: $blue;
                font-weight: bold;
                width: calc(100% - 3.5rem);

                &:hover {
                    background-color: $blue-dark-3;
                    color: $white;
                    border: 1px solid rgba(255, 255, 255, 0.2);
                    box-sizing: border-box;
                    box-shadow: 0px 4px 40px rgba(123, 121, 122, 0.2);
                }
            }
        }
    }
}