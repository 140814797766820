@import "../../assets/scss/variable";

.browse-content {
    .bar-blue {
        top: 220px;

        @media (max-width: $bp-sidebar-hamburger) {
            top: 396px;
        }

        @media (min-width: calc($bp-sidebar-hamburger + 1px)) and (max-width: $bp-sidebar-top) {
            top: 270px;
        }
    }

    .header {
        align-items: center;
        display: inline-flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 6rem 0 0 0;
        width: 100%;
        height: 137px;

        @media all and (max-width: $bp-sidebar-hamburger) {
            background: none;
            display: inline-block;
            height: 200px;
        }

        &--title {
            h1 {
                font-size: 32px;
                font-weight: 400;

                &.long {
                    @media all and (max-width: $bp-sidebar-hamburger) {
                        display: none;
                    }
                }

                &.short {
                    @media all and (min-width: calc($bp-sidebar-hamburger + 1px)) {
                        display: none;
                    }
                }
            }

            h2 {
                font-size: 16px;
                font-weight: 400;
            }
        }

        @media all and (max-width: $bp-sidebar-hamburger) {
            margin-top: 13rem;
        }

        @media (min-width: calc($bp-sidebar-hamburger + 1px)) and (max-width: $bp-sidebar-top) {
            margin-top: 9rem;
        }
    }

    .button-browse {
        margin-right: 1.5rem;
        white-space: nowrap;

        @media all and (max-width: $bp-sidebar-hamburger) {
            margin: 1.5rem;
        }

        .link {
            text-decoration: none;
        }

        button {
            align-items: center;
            display: inline-flex;
            justify-content: space-between;

            &.btn--block-rounded {
                font-weight: 700;
                padding-left: 1rem;
                width: 100%;
            }

            .circle {
                background-color: $blue-light-30;
                border-radius: 200px;
                color: $blue;
                height: 32px;
                margin-left: 2rem;
                width: 32px;

                &:active {
                    background-color: $white;
                    color: $white;

                    svg {
                        color: $blue;
                    }
                }

                &:hover {
                    background-color: $blue-light-20;
                    transition: 0.2s ease-in-out;
                }

                svg {
                    padding: 0.3rem;
                }
            }
        }
    }

    .narrow {
        display: flex;
        justify-content: center;
        margin: 0.5rem 0 0 7.5rem;

        .card-inner-hr {
            &:before {
                content: "";
                position: absolute;
                right: 0;
                width: 100%;
                border: 0.5px solid $gray-neutral;
            }
        }

        @media all and (max-width: $bp-sidebar-small) and (min-width: calc($bp-sidebar-top + 1px)) {
            margin-left: 7.5rem;
        }

        @media all and (max-width: $bp-sidebar-top) {
            margin-left: 0;
        }

        @media all and (max-width: $bp-browse-stacked) {
            flex-direction: column-reverse;
        }

        &-long {
            display: inline-flex;

            @media all and (max-width: $bp-browse-stacked) {
                display: flex;
                flex-direction: column;
            }
        }
        .browse-item{
            min-width: 24%;
            margin-top: .25em;
            margin-bottom: .25em;
            
        }
        .browse-card--links {          
            div {
                &.browse-card--option {
                    align-items: center;
                    background-color: $purple-light;
                    display: flex;
                    margin-bottom: 0.5rem;
                    margin-right: 1.5rem;
                    padding-right: 1rem;
                    width: 100%;
                    
                    @media (max-width: $bp-browse-card-single) {
                        flex-direction: column;
                        margin: 0;
                        padding-right: 0;
                    }
                                      
                    @media all and (max-width: $bp-browse-card-wrap) {
                        flex-wrap: wrap;
                    }

                    @media (min-width: calc($bp-browse-card-single + 1px)) and (max-width: $bp-browse-stacked) {
                        margin-left: 1.5rem;
                    }

                    @media all and (max-width: $bp-browse-card-single) {
                        margin-bottom: 1.5rem;
                        padding-bottom: 1rem;
                    }

                    &.tall {
                        flex-wrap: wrap;
                        margin-top:.5rem;
                        padding-right: 0;
                        margin-right: 0;
                        margin-left: 0;
                    }
                }

                &.browse-card--option-title {
                    background-color: $purple-light;
                    border-left: 1px solid $green-1;
                    color: $green-1;
                    display: inline-flex;
                    font-weight: 500;
                    height: 110px;
                    justify-content: center;
                    line-height: 1;
                    margin-right: 1rem;
                    padding: 1rem 1rem;
                    position: relative;
                    text-align: center;
                    text-transform: uppercase;
                    transform: rotate(180deg);
                    white-space: nowrap;
                    writing-mode: vertical-rl;
                    
                    @media all and  (max-width: $bp-browse-card-single) {
                        height: auto;
                        margin-right: 0;
                        margin: 1rem 0;
                        padding: 0.5rem 0rem;
                        transform: none;
                        width: 100%;
                        writing-mode: unset;
                    }

                    @media all and (max-width: $bp-browse-card-single) {
                        border-left: unset;
                        border-bottom: 1px solid $green-1;
                    }

                    &.tall {
                        height: 230px;
                        @media all and  (max-width: $bp-browse-card-single) {
                            height: auto;
                        }
                    }
                }
            }
        }

        .favorites-spinner {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}
