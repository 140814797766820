@import "../../assets/scss/variable";

.add-persona-user-modal-container{

    .md{
        height: revert;
    }
    .add-persona-user-modal {
        margin-top: 60px;
        align-items: center;
        h2 {
            color:var(--primary-02, #001E2E);;
            display: flex;
            font-size: 24px;
            font-weight: 400;
            justify-content: space-around;
            line-height: 1.8rem;
            margin: 2rem 3rem 0 3rem;
            text-align: center;
        }
    
        .formInput {
            margin: 3.5rem 3rem;
        
            textarea[type="textarea"] {
                width: 100%;
                height: revert;
            }
        }
        button {
            font-weight: 500;
            height: 51px;
            width: 335px;
            margin: 1rem 0 2.5rem 0;
            justify-content: center;
            align-items: center;
        }
        .submit-btn {
            display: inline-flex;
            flex-direction: column;
            width: 100%;
    
            .faq-modal-spinner {
                margin: 2rem 0 0 1rem;
            }
        }
        
    }
}