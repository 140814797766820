@import "../../assets/scss/_variable.scss";

.dropdown {
    * {
        margin: 0;
        padding: 0;
    }

    margin: 10rem 0.5rem;
    position: relative;
    user-select: none;
    min-width: 127px;

    @media all and (max-width: 1024px) and (min-width: 769px) {
        min-width: 105px;
    }

    @media screen and (min-width: 1280px) {
        min-width: 112px;
    }

    .dropdown-btn {
        align-items: center;
        background: #fff;
        border-radius: 200px;
        border: 1px solid $gray-neutral;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        color: $black-2;
        cursor: pointer;
        display: flex;
        font-weight: 400;
        justify-content: space-between;
        padding: 15px 20px;
    }

    .dropdown-content {
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        color: #333;
        font-weight: 500;
        left: 0;
        padding: 10px;
        position: absolute;
        top: 110%;
        width: 341px;

        .dropdown-item {
            cursor: pointer;
            padding: 10px;
            transition: all 0.2s;

            .input-container {
                &:hover {
                    background: #f4f4f4;
                }
            }
        }
    }
}

.dropdownmenu {
    margin-bottom: 3.1rem;
    font-size: 0.87rem;
    text-align: left;

    .error-message {
        color: $red;
        display: flex;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.3rem;
        margin-top: 0.5rem;
    }
}


.dropbtn {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: transparent url(../../assets/icons/arrow-down.svg) no-repeat 297px;
    border-radius: 4px;
    border: 1px solid $gray-neutral-3;
    box-sizing: border-box;
    color: $gray-neutral-3;
    height: 54px;
    padding-left: 1rem;
    padding: 1rem 1rem 1rem;
    width: 335px;
    cursor: pointer;
}


.open {
    border-radius: 4px 4px 0 0;
    background: transparent url(../../assets/icons/arrow-up.svg) no-repeat 297px;
}

.dropdown-content,
.dropdown-content-show {
    display: none;
    position: absolute;
    background: #fff;
    z-index: 1;
    width: 333px;
    cursor: pointer;
}

.dropdown-content-show {
    display: block;
    max-height: 200px;
    overflow: auto;
    div {
        color: #333;
        padding: 12px 16px;
        text-decoration: none;

        &:hover {
            background: $gray-neutral-2;
        }
    }

    .search-bar {
        background-color: $gray-neutral;
        display: inline-flex;

        .search-bar-input {
            display: flex;
            width: 100%;
            height: 40px;
            border: unset;
            border-radius: 4px;
            padding-left: 1rem;
            padding-right: 10rem;
        }

        button {
            width: 16px;
            height: 16px;
            margin-left: -20px;
            margin-bottom: 10px;
            display: inline-block;
            cursor: pointer;
            background-color: transparent;
            border: none;
            position: relative;
            bottom: 0.5rem;
            right: 0.5rem;
        }
    }
}