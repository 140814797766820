@import "../../assets/scss/variable";

.btn {
    align-items: center;
    align-self: center;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    display: inline-flex;
    font-family: "Effra", sans-serif;
    font-size: 1rem;
    font-weight: 400;
    justify-content: center;
    letter-spacing: 1px;
    min-height: 35px;
    min-width: 94px;
    outline: 0;
    position: relative;
    text-align: center;
    text-transform: capitalize;

    &:disabled {
        cursor: not-allowed;
        color: $gray-light-2;
        background-color: $gray-neutral-2
    }

    &--block {
        width: 95%;
        margin: 0 auto;
    }

    &--block-rounded {
        width: 95%;
        margin: 0 auto;
        border-radius: 200px;
    }

    &--disabled {
        cursor: default;
        opacity: 0.4;
    }

    &--outline {
        border: 1px solid $white;
    }

    &--rounded-outline-blue {
        background: transparent;
        border-radius: 25px;
        border: 1px solid $blue-dark-3;
        box-sizing: border-box;
        color: $blue-dark-3;
        font-weight: 100;
        line-height: 1.1rem;
        padding: 0.9rem 1rem;
    }

    &--rounded-outline-gray {
        background: transparent;
        border-radius: 4px;
        border: 1px solid $gray-neutral-3;
        box-sizing: border-box;
        color: $black-2;
        height: 54px;
        margin-bottom: 3.1rem;
        margin-top: -2rem;
        width: 191px;
    }

    &--rounded-outline {
        background-color: transparent;
        border-radius: 25px;
        border: 1px solid $white;
        color: $white;
        font-weight: 100;
        line-height: 1.1rem;
        padding: 0.9rem 1rem;

        &:hover {
            background: rgba(255, 255, 255, 0.2);
            border: 1px solid rgba(255, 255, 255, 0.2);
            box-sizing: border-box;
            box-shadow: 0px 4px 40px rgba(123, 121, 122, 0.2);
        }

        svg {
            &:active {
                color: $blue;
            }
        }

        &:active {
            background-color: $white;
            color: $blue;
        }
    }

    &--no-outline {
        background: transparent;
        border: none;

        &.red {
            color: $red-1;
        }
    }

    &--rounded {
        border-radius: 25px;
    }

    &--publish {
        border-radius: 25px;
        width: 210px;
        height: 51px;
        color: $gray-neutral-7;
        font-weight: 700;
       
        pointer-events: none;
        .button-text-draft {
            margin-right:65px;
            background: transparent;
        }
        .button-text {
            margin-right:90px;
            background: transparent;
        }
        
        svg {
            background-color: $white;
        }
        
        &.blue {
            pointer-events:visible;
            background-color: $blue-light-10;
            color: $blue;
            svg {
                background-color: $blue-light-30; 
            }

            &:hover {
                background-color: $blue-light-20;
                transition: 0.2s ease-in-out;
            }
        }
    }

    

    &--blue-light {
        background-color: $blue-light-10;
        color: $blue;
        font-weight: bold;
        line-height: 1.1rem;
        padding: 8px 10px;

        &:hover {
            background-color: $blue-light-20;
            transition: 0.2s ease-in-out;
        }

        &:active {
            background-color: $blue;
            color: $white;
        }

        &:disabled {
            cursor: not-allowed;
            background-color: $gray-neutral;
        }
        
        &.wide {
            width: 220px;
            height: 50px;
        }
    }

    &--blue-white {
        background-color: $white;
        color: $blue;
        font-weight: 500;
        line-height: 1.1rem;
        padding: 12px 12px;
        border: 1px solid $blue-light;

        &:hover {
            background-color: $blue-light-20;
            transition: 0.2s ease-in-out;
        }

        &:active {
            background-color: $blue;
            color: $white;
        }

        &.no-outline {
            border: none;
        }
    }

    &--red-light {
        background-color: $red-light-10;
        color: $red;
        font-weight: 100;

        &:hover {
            background-color: $red-light-20;
            transition: 0.2s ease-in-out;
        }

        &:active {
            background-color: $red;
            color: $white;
        }
    }

    &-row--action {
        &:hover {
            background-color: $gray-neutral-3;
        }
    }

    &--plain-underline {
        background: transparent;
        font-size: 14px;
        outline: none;

        span {
            text-decoration: none;
            position: relative;
        }

        span:after {
            content: '';

            width: 100%;
            position: absolute;
            left: 0;
            bottom: -2px;

            border-width: 0 0 1px;
            border-style: solid;
            color: $blue;
        }
    }
}

.mr-1 {
    margin-right: 1rem;
}

.mr-2 {
    margin-right: 2rem;
}