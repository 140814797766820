@import "../../assets/scss/variable";

.product-access-origin {
    display: flex;
    align-items: center;
    .report-type {
        display: inline-flex;
        align-items: center;

        &.spoke {
            svg {
                path {
                    fill: $gray-neutral-7;
                }
            }
        }
    
        &.hub {
            svg {
                path {
                    stroke: $gray-neutral-7;
                }
            }
        }
    }

    svg {
        width: 18px;
        height: 18px;
        margin-right: 0.3rem;

        &.access-type {
            path {
                stroke: $gray-neutral-7;
            }
        }
    }

    p {
        font-size: 12px;
        font-weight: 500;
        line-height: 21px;
        text-transform: uppercase;
        color: $gray-neutral-7;
    }

    .dark-blue {
        color: $blue-dark-2;
    }

    .dot-separator {
        padding: 0 0.5rem 0 0.5rem;
    }
}