@import "../../assets/scss/variable";

.confirm-modal {
    margin-top: 40px;
    text-align: center;
    h2 {
        color: $black-2;
        display: flex;
        font-size: 1.5rem;
        font-weight: 400;
        justify-content: space-around;
        line-height: 1.8rem;
        margin: 2rem 3rem 0 3rem;
        text-align: center;
    }
    p {
        margin-top: 15px;
        color: $gray-neutral-7;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.8rem;
        padding: 0 9rem;
        text-align: center;
    }
    .button-container{
        
        
        button {
            font-weight: 500;
            height: 51px;
            width: 300px;
            margin: 1rem 0 2.5rem 0;
            margin-right: 15px;
            margin-top: 30px;
            
        }
    }
}