@import '../../assets/scss/variable';


.navbar-menu {
    ::-webkit-scrollbar {
        width: 0;  /* Remove scrollbar space */
        background: transparent;  /* Optional: just make scrollbar invisible */
    }
    .logo {
        bottom: 0%;
        height: 47px;
        left: 40px;
        position: fixed;
        right: 0%;
        top: 1.3rem;
        width: 74px;
        z-index: 10;
        display: block;
        margin-bottom: 3rem;

        @media (max-width: $bp-sidebar-small) and (min-width: calc($bp-sidebar-top + 1px)) {
            left: 20px;
        }
    }
    .menu-bars {
        color: $white;
        right: 1.8rem;
        position: fixed;
        top: 1.5rem;
        z-index: 30;

        @media (min-width: calc($bp-sidebar-hamburger + 1px)) {
            display: none;
        }
    }

    .overlay {
        background: rgba(0, 0, 0, 0.2);
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 22;
    }

    nav {
        #sidebar-menu-faq{
            display:none
        }
        &.sidebar-menu-help-no{
            display : none;
        }            
        &.sidebar-menu {
            align-items: center;
            background-color: $blue-dark-3;
            display: flex;
            flex-direction: column;
            height: 100%;
            left: 0px;
            min-width: 135px;
            position: fixed;
            z-index: 5;
            //overflow-y: scroll;

            .recommendation-container{
                height: 75px;
                display: flex;
                position: relative;
                margin-bottom: 2.8rem;

                @media all and (max-width: $bp-sidebar-top) {
                    width:unset;
                    margin-bottom: unset;
                }

                .recommendation-popup{
                    z-index:5;
                    position:absolute;
                    top:40%;
                    display:flex;
                    align-items: center;

                    @media all and (max-width: $bp-sidebar-top) {
                        top: 115%;
                    }
                }

                a:first-child{
                    margin-top: unset;
                }
            }

            .sidebar-menu-help-container{
                position: relative;
                width: 115%;
                height: 75px;
                display: flex;
                margin-bottom: 2.8rem;
                @media all and (max-width: $bp-sidebar-top) {
                    width:unset;
                    margin-bottom: unset;
                }

                @media (max-width: calc($bp-sidebar-hamburger)) {
                    display: none;
                }

                a:first-child{
                    margin-top: unset;
                    left: 13%;
                    position: relative;

                    @media all and (max-width: $bp-sidebar-top) {
                        left: unset;
                    }
                }

                .sidebar-menu-help{
                    align-items: center;
                    background-color: $blue-dark-3;
                    display: flex;
                    flex-direction: column;
                    left: 140px;
                    min-width: 120px;
                    position: fixed;
                    z-index: 5;
                    border-radius: 8px;
        
                    @media (max-width: $bp-sidebar-small) and (min-width: calc($bp-sidebar-top + 1px)) {
                        min-width: 125px;
                        left: 130px;
                    }
        
                    @media all and (max-width: $bp-sidebar-top) {
                        align-items: flex-start;
                        background-color: $blue-dark-3;
                        display: flex;
                        flex-direction: column;
                        min-width: 100px;
                        position: fixed;
                        top: 150px;
                        z-index: 5;
                        left: unset;
                    }
        
                    @media (max-width: calc($bp-sidebar-hamburger)) {
                        display: none;
                    }
                }
                .sidebar-menu-help a{
                    align-items: flex-start;
                    height : 0px;
                    margin-top: 0.2rem; 
                    margin-left: 10px;
                    margin-bottom: 2.2rem; 
                    
                    &:hover:not(.active) {
                            color:white
                    }
        
                    @media all and (max-width: $bp-sidebar-top) {
                        margin-top:0.2rem;
                        height:10px;
                        margin-bottom: 1.2rem;
                        margin-right: 10px
                    }
                }
                .sidebar-menu-help a:first-child{
                    margin-top: 1.2rem;
                    left: 0px;
        
                    @media all and (max-width: $bp-sidebar-top) {
                        margin-left: 10px;
                        margin-top: 1rem;
                        margin-bottom: 1.2rem;
                    }
                    
                }

                
            }

            @media (max-width: $bp-sidebar-small) and (min-width: calc($bp-sidebar-top + 1px)) {
                min-width: 125px;
            }

            @media all and (max-width: $bp-sidebar-top) {
                align-items: center;
                background-color: $purple-light;
                border-right: 2px solid $gray-neutral-3;
                display: inline-flex;
                flex-direction: row;
                height: 54px;
                left: 0px;
                min-width: 100px;
                position: fixed;
                top: 96px;
                width: 100%;
                z-index: 5;
                overflow:hidden;
            }

            @media (max-width: calc($bp-sidebar-hamburger)) {
                display: none;
            }
        }

        &.hamburger-menu {
            @media (min-width: calc($bp-sidebar-hamburger + 1px)) {
                display: none;
            }

            .sidebar-menu-help-container{
                display: none
            }

            align-items: center;
            background-color: $blue-dark-3;
            flex-direction: column;
            height: 100%;
            left: 0px;
            position: fixed;
            top: 0px;
            width: calc(95% - 4rem);
            max-width: 22rem;
            z-index: 50;

            a {
                width: 100%;
                height: 88px;
                border-bottom: 1px solid $gray-neutral;
                padding: 0.5rem 0rem;
                border-radius: unset;
                margin: 0rem;

                &.active {
                    background-color: $blue-light-10;
                    color: $blue;
                }

                &:first-child {
                    margin: 3rem 0 0 0rem;
                }

                svg {
                    margin-left: 1.5rem;
                }
            }
        }

        .add-mktpl-btn {
            margin: 0 auto;
            display: flex;
            position: absolute;
            bottom: 1.5rem;
            left: 1.5rem;
            width: calc(100% - 3rem);

            @media (min-width: calc($bp-sidebar-hamburger + 1px)) {
                display: none;
            }
        }

        .nav-block {
            &.block {
                display: none;
            }
            
        }

        .nav-fill {
            display: none;

            &.active {
                display: block;
            }
        }

        a {
            color: $gray-neutral-4;
            margin-bottom: 2.8rem;
            text-decoration: none;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 115px;
            height: 75px;
            border-radius: 8px;
            &.active {
                background-color: $blue-light-10;
                color: $blue;

                @media all and (max-width: $bp-sidebar-top) {
                    background-color: unset;
                }
            }

            @media all and (max-width: $bp-sidebar-top) {
                margin: 0 1rem;
                flex-direction: row;
                width: unset;
            }

            @media (min-width: $bp-sidebar-top) and (max-width: $bp-sidebar-small) {
                width: 110px;
            }

            svg {
                margin: 0.3rem 0 ;

                @media all and (max-width: $bp-sidebar-top) {
                    margin-right: 0.5rem;
                    margin-bottom: 0;
                }
            }

            &:first-child {
                margin-top: 8rem;

                @media all and (max-width: $bp-sidebar-top) {
                    margin: 0 1rem;
                }
            }

            &:hover:not(.active) {
                color: $white;

                @media (max-width: $bp-sidebar-top) and (min-width: calc($bp-sidebar-hamburger + 1px)) {
                    color: $blue-dark-3;
                }
                
                svg {
                    path {
                        stroke: $white;

                        @media (max-width: $bp-sidebar-top) and (min-width: calc($bp-sidebar-hamburger + 1px)) {
                            stroke: $blue-dark-3;
                        }
                    }
                }
            }
        }
    }
}
