@import "../../assets/scss/variable";


.permission-settings--container {
    .bar-blue {
        @media all and (max-width: $bp-sidebar-top) {
            top: 350px;
        }
        @media (max-width: $bp-sidebar-hamburger) {
            top: 390px;
        }
    }

    .header {
        @media all and (max-width: $bp-sidebar-top) {
            height: 200px;
        }

        &--title {
            h2 {
                &.long {
                    @media all and (max-width: $bp-sidebar-hamburger) {
                        display: none;
                    }
                }

                &.short {
                    @media all and (min-width: calc($bp-sidebar-hamburger + 1px)) {
                        display: none;
                    }
                }
            }
        }
    }

    .back-button {
        @media all and (max-width: $bp-sidebar-top) {
            margin-top: 1rem;
        }
    }

    .result {
        width: fit-content;
        position: relative;
        left: 11rem;
        padding-top: 1rem;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        display: flex;
        align-items: center;
        color: $gray-neutral-7;

        @media all and (max-width: $bp-sidebar-small) and (min-width: calc($bp-sidebar-top + 1px)) {
            left: 9rem;
        }

        @media all and (max-width: $bp-sidebar-top) {
            left: 1.5rem;
        }

        p {
            padding: 20px 0 20px 0;
        }
    }
    
    .datatable {
        .dropdown-row {
            margin: -0.5rem 1.5rem .5rem 10rem;

            @media all and (max-width: $bp-sidebar-small) and (min-width: calc($bp-sidebar-top + 1px)) {
                margin-left: 9rem;
            }

            @media all and (max-width: $bp-sidebar-top) {
                margin-left: 1.5rem;
            }
        }
    }

    .loading-spinner {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-top: 4rem;
    }

    .add-a-record-btn {
        position: absolute;
        margin: 1.3rem 0 0 10rem;
        padding: 1rem 3rem;

        @media all and (max-width: $bp-sidebar-small) {
            margin-left: 9rem;
        }

        @media all and (max-width: $bp-sidebar-top) {
            margin-left: 1.5rem;
        }

        @media all and (max-width: $bp-sidebar-hamburger) {
            padding: 1rem 1rem;;
        }
    }

    table {

        thead,
        tbody {
            tr {
                display: none;
            }
        }

        tbody {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: stretch;

            &:before {
                display: none;
            }

            tr {
                background: $white;
                border-radius: 8px;
                border: 1px solid $gray-neutral;
                box-shadow: 0px 4px 20px rgba(10, 56, 217, 0.02);
                display: inline-block;
                margin-bottom: 1rem !important;
                padding: 1rem 1.5rem 0 1.5rem;
                position: relative;

                td {
                    width: auto;
                    height: 40px;

                    .cell--title {
                        display: block;
                        font-family: "Effra";
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 14px;
                        color: $gray-light-2;
                        text-transform: uppercase;
                        white-space: nowrap;
                        margin-bottom: 0.2rem;
                    }

                    &:first-child {
                        width: auto;
                        margin-bottom: 1.5rem;

                        button {
                            background: none;
                            color: $black-2;
                            cursor: pointer;
                            font-family: "Effra";
                            font-size: 1rem;
                            font-weight: 500;
                            overflow: hidden;
                            position: relative;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }

                        @media (max-width: $bp-search-row-condense) {
                            padding-right: 1.5rem;
                        }

                        img {
                            visibility: hidden;
                        }

                        button {
                            align-items: center;
                            color: $black-2;
                            font-size: 1rem;
                            font-weight: 500;
                            width: auto;
                            text-align: start;
                            overflow: hidden;
                            text-overflow: ellipsis;

                            @media (max-width: $bp-search-row-condense) {
                                text-align: start;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: normal;
                                overflow-wrap: anywhere;
                            }
                        }

                        img.arrow-separator {
                            display: block;
                            visibility: visible;
                            height: 10px;
                            width: 10px;
                            transform: rotateZ(90deg);
                            margin-left: 0.3rem;
                        }
                    }

                    &:nth-child(3) {
                        visibility: hidden;
                        position: absolute;
                        top: 1rem;

                        img {
                            visibility: visible;
                        }
                    }

                    &:nth-child(2),
                    &:nth-child(4),
                    &:nth-child(5),
                    &:nth-child(6) {
                        display: inline-flex;
                        flex-direction: column;
                        align-items: flex-start;
                        margin-right: 1rem;
                        font-family: "Effra";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 19px;
                        color: $gray-light-2;
                        margin-bottom: 1rem;
                        min-width: 200px;

                        img {
                            height: 17px;
                            margin-right: 0;
                            padding-right: 0.5rem;
                        }
                    }

                    &:nth-child(4) {
                        width: 190px;
                        margin-right: 2rem;
                    }

                    &:nth-child(6) {
                        margin-right: 2rem;

                        @media (max-width: $bp-search-row-condense) {
                            display: none;
                        }
                    }

                    &:nth-child(7) {
                        position: absolute;
                        top: 1rem;
                        right: 0.75rem;
                    }

                    &:nth-child(8) {
                        display:inline-flex;
                        margin-bottom: 1.75rem;
                        margin-left: 1rem;

                        button{
                            padding: 0.5rem 1rem 0.5rem 1rem;
                            font-weight: 500;
                        }
                        .default-record{
                            button{
                                font-weight: 200;
                                font-style: italic;
                            }
                            
                        }
                    }
                }
            }
        }
    }
}