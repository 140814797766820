@import "../../assets/scss/variable";

.recommended-item {
    box-shadow: 0px 2px 20px rgba(10, 56, 217, 0.1);
    display: flex;
    justify-content: space-between;
    justify-items: center;
    align-items: center;
    margin: 0 0 1rem 0;
    cursor: pointer;
    background: $white;
    border-radius: 8px;
    border: 1px solid $gray-neutral;
    box-sizing: border-box;
    padding: 15px;
    font-weight: 400;
    font-size: 16px;
    color: $gray-neutral-7;

    @media all and (max-width: $bp-sidebar-hamburger) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .recommended-date {
        @media all and (max-width: $bp-sidebar-hamburger) {
            margin-top: 1rem;
        }
    }

    &.orange {
        border-left: 8px solid $orange-light-30;

        &:hover {
            box-shadow:
            0px 2px 20px rgba(10, 56, 217, 0.1),
            0 0 0 1px $orange;
        }
    }

    &.blue {
        border-left: 8px solid $blue-light-30;

        &:hover {
            box-shadow:
            0px 2px 20px rgba(10, 56, 217, 0.1),
            0 0 0 1px $blue-light;
        }
    }

    &.purple {
        border-left: 8px solid $purple-30;

        &:hover {
            box-shadow:
            0px 2px 20px rgba(10, 56, 217, 0.1),
            0 0 0 1px $purple;
        }
    }

    &.green {
        border-left: 8px solid $green-30;
        cursor:unset;    
    }

    .recommended-content {
        display: inline-flex;
        align-items: center;

        img {
            height: 40px;
            width: 40px;
        }
        .arrow-pointer{
            height: 14px;
            width: 14px;
            margin-left: 0.5rem
        }

        .arrow-pointer[src='']{
            display: none
        }

        p {
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            overflow-wrap: anywhere;
            color: $black-2;

            a {
                font-weight: 500;
                text-decoration: none;
                border-bottom: 1px solid $blue;
                padding-bottom: 0.1rem;
                color: $black;

                &:active {
                    color: unset;
                }
            }
        }
    }
}