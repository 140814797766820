@import "../../assets/scss/variable";

.favorite-card {
    background: $white;
    border-radius: 8px;
    border: 1px solid $gray-neutral;
    box-shadow: 0px 2px 20px rgba(10, 56, 217, 0.1);
    box-sizing: border-box;
    display: block;
    margin: 0.75rem 0 0.75rem 0.75rem;
    max-height: 641px;
    min-height: 527px;
    position: relative;
    width: 392px;

    hr {
        background-color: $gray-neutral;
        border: 0;
        height: 1px;
        margin-bottom: 1.5rem;
        size: 1px;
        width: 100%;
    }

    .link {
        text-decoration: none;
    }

    &--header-box {
        width: 100%;
        display: inline-flex;
        border-radius: 8px 8px 0 0;
        background-color: $blue-dark-3;
        margin-bottom: 1rem;
        align-items: center;

        svg {
            margin-left: 1.5rem;
            height: 17px;
            width: 20px;
            path {
                stroke: $white;
            }
        }
    }

    &--title {
        color: $white;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.02em;
        line-height: 19px;
        margin-left: 1.5rem;
        padding: 1.5rem 0;
        text-transform: uppercase;
    }

    .favorite-button {
        align-items: center;
        display: inline-flex;
        font-weight: 700;
        justify-content: space-between;
        margin: 0.5rem 0 0.75rem 0;
        padding-left: 1.5rem;
        text-align: left;
       
        .circle {
            background-color: $blue-light-30;
            border-radius: 200px;
            color: $blue;
            height: 32px;
            width: 32px;

            &:active {
                background-color: $white  !important;
                color: $white;

                svg {
                    color: $blue;
                }
            }

            &:hover {
                background-color: $blue-light-20;
                transition: 0.2s ease-in-out;
            }

            svg {
                padding: 0.3rem;
            }

            @media (max-width: $bp-sidebar-small) {
                background-color: $white;
            }
        }
    }

    .favorite-card--children {
        align-items: center;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        max-height: 420px;
        overflow-x: hidden;
        padding-top: 0.5rem;
        
        .product-tile {
            margin-left: 1rem;
            width: 355px;
        }

        .favorite-card--favorite-all {
            bottom: 0;
            position: absolute;
            width: 100%;
            margin-right: 1rem;
            background-color: white;
            padding-top: .75rem;
            padding-bottom: .75rem;

            a {
                padding-left: 2.5%;
            }
        }
    }

    @media all and (max-width: $bp-browse-narrow) {
        margin-left: 0.25rem;
    }

    @media (max-width: $bp-sidebar-hamburger) {
        margin: 0;
        width: 388px;

        div.favorite-card--children {
            .favorite-card--links {
                .product-tile {
                    width: auto;
                }
            }
        }
    }

    @media all and (max-width: $bp-browse-stacked) {
        height: 209px;
        left: 0;
        margin: 1rem 2rem;
        min-height: auto;
        position: relative;
        width: auto;

        hr {
            margin-bottom: 1rem;
        }

        div.favorite-card--children {
            left: 0.5rem;
            max-height: auto;
            min-height: auto;
            height: auto;
            overflow: visible;
            padding: 0;
            position: absolute;
            right: 1.5rem;

            .favorite-card--links {
                display: inline-flex;
                overflow-x: auto;
                width: 100%;
                padding: 0.5rem 0;

                .product-tile {
                    margin-bottom: 0;
                }
            }

            .favorite-card--favorite-all {
                top: -5.25rem;
                bottom: auto;
                right: -1.5rem;
                width: auto;
                background-color: unset;

                span {
                    white-space: nowrap;

                    &.browse-all--title {
                        color: $white;
                        margin-right: 0.5rem;

                        @media all and (max-width:$bp-browse-small){
                            display:none;
                        }
                    }

                    &.browse-all--name {
                        display: none;
                    }
                }

                .favorite-button {
                    background-color: transparent;
                    margin: 0rem 0 0.75rem 0.5rem;
                }
            }
        }
    }
}