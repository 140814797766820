@import "../../assets/scss/variable";

.formInput {
    label {
        color: $gray-neutral-7;
        display: flex;
        font-size: 1.1rem;
        font-weight: 400;
        line-height: 1.3rem;
        margin-bottom: 0.5rem;
        word-wrap:break-word;

        @media (max-width: calc($bp-sidebar-hamburger + 1px)) {
            width: 95vw;
        }
    }
    .error-message {
        color: $red;
        display: flex;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.3rem;
    }
    label {
        &.error-label {
            margin-top: 3rem;
            color: $red;
        }
    }
    input:invalid[focused="true"] {
        border: 1px solid $red;
        color: $black-2;
    }

    textarea[type="textarea"] {
        border-radius: 4px;
        border: 1px solid $gray-neutral-4;
        box-sizing: border-box;
        padding-left: 1rem;
        width: 625px;
        height: 115px;
        padding-top: 1rem;

        &.input-file {
            display: none;
        }
        &::placeholder {
            color: $gray-neutral-4;
        }

        @media (max-width: calc($bp-sidebar-hamburger + 1px)) {
            width: 85vw;
        }
    }
}