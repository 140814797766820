@import "../../assets/scss/variable";

.product-tile {
    box-shadow: 0px 2px 20px rgba(10, 56, 217, 0.1);
    display: inline-flex;
    height: 100px;
    justify-content: space-between;
    justify-items: center;
    align-items: center;
    margin: 0 0 1rem 0;
    min-width: 355px;
    cursor: pointer;
    background: $white;
    border-radius: 8px;
    border: 1px solid $gray-neutral;
    box-sizing: border-box;
    padding: 15px;

    &.orange {
        border-top: 8px solid $orange-light-30;

        &:hover {
            box-shadow:
            0px 2px 20px rgba(10, 56, 217, 0.1),
            0 0 0 1px $orange;
        }
        &:active {
            background: $orange-light-20;
        }
    }

    &.blue {
        border-top: 8px solid $blue-light-30;

        &:hover {
            box-shadow:
            0px 2px 20px rgba(10, 56, 217, 0.1),
            0 0 0 1px $blue-light;
        }

        &:active {
            background: $blue-light-20;
        }
    }

    &.purple {
        border-top: 8px solid $purple-30;

        &:hover {
            box-shadow:
            0px 2px 20px rgba(10, 56, 217, 0.1),
            0 0 0 1px $purple;
        }
        
        &:active {
            background: $purple-20;
        }
    }

    .product-actions {
        height: 100%;
        display: inline-flex;
        flex-direction: column;

    }

    .product-name {
        min-width: 16rem;
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        padding-bottom: 0.5rem;
        justify-content: flex-start;

        img {
            height: 23px;
        }

        .product-name--name {
            font-size: 16px;
            font-weight: 500;
            line-height: 21px;
            color: $black-2;
            position: relative;
            word-break: break-word;
            overflow: hidden;
            max-height: 42px;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }

    .product-access {
        align-items: center;
        margin-top: .5rem;
    }

    .product-actions {
        img {
            width: 22px;
            height: 22px;
            padding-top: 1.25rem;
        }
    }
}