@import "../../assets/scss/variable";


.glossary--container {
    .bar-blue {
        @media (max-width: $bp-sidebar-hamburger) {
            top: 375px;
        }
    }

    .back-button {
        @media all and (max-width: $bp-sidebar-top) {
            margin-top: 1rem;
        }
    }
    
    background: linear-gradient(180deg, $white 21.04%, $gray-neutral-1 43.32%);
    height: 100vh;

    .data-container {
        padding-bottom: 1px;

        .datatable {
            .dropdown-row {
                margin: -1rem 0 .5rem 10rem;

                @media all and (max-width: $bp-sidebar-small) and (min-width: calc($bp-sidebar-top + 1px)) {
                    margin-left: 9rem;
                }

                @media all and (max-width: $bp-sidebar-top) {
                    margin-left: 1.5rem;
                }
            }
        }

        .result {
            width: fit-content;
            position: relative;
            left: 11rem;
            padding-top: 1rem;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 21px;
            display: flex;
            align-items: center;
            color: $gray-neutral-7;

            @media all and (max-width: $bp-sidebar-small) and (min-width: calc($bp-sidebar-top + 1px)) {
                left: 9rem;
            }

            @media all and (max-width: $bp-sidebar-top) {
                left: 1.5rem;
            }

            p {
                padding: 20px 0 20px 0;
            }
        }
    }

    .loading-spinner {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-top: 8rem;
    }

    .data-definition--container {
        position: relative;
        
        .search-global-filter {
            position: relative;
            top: -9rem;
            right: 1.5rem;

            input {
                background: $white;
                border-radius: 200px;
                border: 1px solid $gray-neutral;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
                box-sizing: border-box;
                height: 3rem;
                padding-left: 2rem;
                padding-right: 8.5rem;
                width: 37rem;
                position: absolute;
                right: 0;

                @media all and (max-width: 1072px) {
                    width: auto;
                    left: 29rem;
                }

                &:focus {
                    outline: none;
                }
            }

            button {
                display: inline-flex;
                line-height: 1.5rem;
                background: $blue-light-10;
                border-radius: 0rem 12.5rem 12.5rem 0rem;
                border: none;
                color: $blue;
                font-size: 1.1rem;
                font-weight: 500;
                padding: 0.74rem;
                position: absolute;
                right: 0;
                width: 8rem;
                height: 47px;

                svg {
                    color: $blue;
                    padding-right: 0.2rem;
                }
            }

            @media (max-width: 800px) {
                input {
                    padding-right: 4rem;
                }

                button {
                    width: 3rem;

                    span {
                        display: none;
                    }
                }

            }

            @media (max-width: $bp-table-hide-filters) {
                display: none;
            }
        }
    }

    .sort {
        position: relative;
    }

    .datatable {
        padding-right: 1.5rem;

        table tbody:before {
            line-height: 0;
        }

        thead {
            display: none;
        }

        tr {
            justify-content: space-between;
            height: auto;
            position: relative;
            box-shadow: 0px 2px 20px rgba(10, 56, 217, 0.1);

            td {
                &:first-child {
                    align-items: flex-start;
                    width: 100%;

                    .title {
                        padding: 1rem 0 0 1rem;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 21px;
                        color: $black-2;
                    }

                    .definition {
                        padding: 0 1rem 0 1rem;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 180%;
                        color: $gray-neutral-7;
                        margin: 0.5rem 7rem 0.2rem 0;

                        @media all and (max-width:$bp-sidebar-hamburger){
                            margin-right:0;
                        }

                        p {
                            padding-top: 1rem;

                            &:first-child {
                                padding-top: 0;
                            }
                            & img{
                                display: none;
                            }                           
                        }                      
                        ul {
                            padding-left: 1.5rem
                        }
                    }
                }

                flex-direction: column;

                .tags {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    justify-content: space-between;
                    position: absolute;
                    right: 0;
                    top: 0;

                    @media all and (max-width : $bp-sidebar-hamburger){
                        margin-left: 1rem;
                        position: relative;
                        display: block;
                        margin-bottom:0.5rem;
                    }

                    .chip {
                        margin-top: 1rem;
                        margin-right: 1rem;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 17px;
                        padding: 4px 12px;
                        border-radius: 200px;
                        color: $white;

                        &.chip--purple {
                            background: $blue;
                        }

                        &.chip--blue {
                            background: $blue-light;
                        }

                        &.chip--green {
                            background: $green;
                        }

                        &.chip--yellow {
                            background: $orange;
                        }

                        &.chip--grey {
                            background: $gray-light-2;
                        }
                    }

                    .type {
                        margin-right: 1rem;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 180%;
                        text-align: right;
                        color: $gray-neutral-7;
                    }
                }

                section.tab {
                    margin-left: 0px;
                    width:100%;
                }

                section {
                    .tab {
                        &--container{
                            height: 50px;

                            &-inactive{
                                display: none;
                            }

                            @media all and (max-width:$bp-sidebar-hamburger){
                                height: auto;
                            }
                            .single-tab--header-container{
                                @media all and (min-width:calc($bp-sidebar-hamburger + 1px)){
                                    display:none;
                                } 
                            }
                            .all-tab--header-container{
                                @media all and (max-width:$bp-sidebar-hamburger){
                                    display:none;
                                } 
                            }
                            .grouped-terms{
                                display: flex;
                                position: absolute;
                                gap: 4px;
                                align-items: flex-start;
                                padding-right: 8px;
                                right: 0px;

                                @media all and (max-width: $bp-sidebar-hamburger){
                                    display: none;
                                }

                                .dropbtn{
                                    height: 40px;
                                    width: auto;
                                    display: flex;
                                    align-items: center;
                                    border-radius: 4px;
                                    border: 1px solid var(--primary-04, $gray-neutral-4);
                                    background: var(--neutral-white, $white);
                                    padding: 8px 16px;
                                    gap: 16px;
                                    margin-right: 0.5rem;
                                    box-shadow: 0px 2px 20px 0px rgba(48, 99, 197, 0.10);

                                    .svg{
                                        width: 16px;
                                        height: 16px;
                                        font-weight: bold;
                                    }

                                    .circle {
                                        background-color: $blue-light-30;
                                        border-radius: 200px;
                                        color: $blue;
                                        height: 32px;
                                        width: 32px;
                                        margin-left: 4px;
                        
                                        &:active {
                                            background-color: $white;
                                            color: $white;
                        
                                            svg {
                                                color: $blue;
                                            }
                                        }
                        
                                        &:hover {
                                            background-color: $blue-light-20;
                                            transition: 0.2s ease-in-out;
                                        }

                                        svg {
                                            padding: 0.3rem;
                                        }
                        
                                    }
                                }
                                .dropdown-content-show{
                                    width: auto;
                                    font-weight: bold;
                                    box-shadow: 0px 2px 20px 0px rgba(48, 99, 197, 0.10);
                                    border: 1px solid var(--primary-04, $gray-neutral-4);
                                    margin-right: 0.85rem;
                                } 
                                
            
                                .btn {
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 12px;
                                    line-height: 10px;
                                    display: flex;
                                    letter-spacing: 0.02em;
                                    color: $gray-neutral-7;
                                    gap: 4px;
                                    background: $white;
                                    margin-right: 0.5rem;

                                    .diamonds-four{
                                        
                                        svg{
                                            width: 16px;
                                            height: 16px;
                                        }
                                    }

                                    .circle {
                                        background-color: $blue-light-30;
                                        border-radius: 200px;
                                        color: $blue;
                                        height: 32px;
                                        width: 32px;
                                        margin-left: 4px;
                        
                                        &:active {
                                            background-color: $white;
                                            color: $white;
                        
                                            svg {
                                                color: $blue;
                                            }
                                        }
                        
                                        &:hover {
                                            background-color: $blue-light-20;
                                            transition: 0.2s ease-in-out;
                                        }

                                        svg {
                                            padding: 0.3rem;
                                        }
                        
                                    }
                                }
                                
                            }
                        }
                        &--inactive {
                            display: none;
                        }
                        &--holder {
                            margin-top: 1rem;;
                            border-bottom: unset;

                            button {
                                display: inline-flex;
                                align-items: center;

                                .default-caret {
                                    padding-bottom: unset;
                                    padding-top: 0.3rem;
                                }
                            }
                        }

                        &--panel {

                            .dashboard-items {
                                overflow: auto;
                                max-height: 340px;
                                display: flex;
                                flex-wrap: wrap;
                                background: $green-5;
                                padding-left: 1rem;
                                padding-bottom: 1rem;
                                padding-top: 1rem;
                                min-width: unset;
        
                                .product-tile {
                                    margin-right: 1rem;
                                    flex: 1 0 21%;
                                    margin-left:1px;
                                    margin-top:2px;
                                }

                                @media all and (min-width: 1281px) {
                                    display: grid;
                                    grid-template-columns: repeat(3, minmax(300px, 535px));
                                }
        
                                @media all and (max-width: 1280px) and (min-width:calc($bp-sidebar-hamburger)) {
                                    display: grid;
                                    grid-template-columns: repeat(2, minmax(300px, 535px));
                                }
                            }

                            .synonym-items {
                                padding: 8px 24px;
                                overflow: auto;
                                max-height: 340px;
                                display: flex;
                                flex-wrap: wrap;
                                background: $green-5;

                                table{
                                    margin-left: 0rem;
                                    min-width:100%;
                                }
                                @media all and (max-width: $bp-sidebar-hamburger){
                                    display: none;
                                }
                            }
                        }

                        &--panel-container {
                            height: auto;
                            &.inactive{
                                display: none;
                            }
                        }
                    }            
                }
            }
        }
    }
}