@import "../../../assets/scss/variable";


.manage-rec-container {
    .header-container {
        display: flex;

        .header--title {
            margin-left: 10.5rem;
        }
        
        .button-container {
            margin-right: 40px;
            margin-top: 70px;
       
            svg.cross {
                height: 25px;
                width: 25px;
                background-color: $blue-light-30;
                border-radius: 40px;
                margin-left:5px;
                margin-right: -5px;
                padding: 3px;
            }

            button {
                width: 230px;
            }
        }
    }
    .tab { 
        margin-left: -.9em;
        .tab--container {
            border-bottom: 1px solid $gray-neutral-4;
            .tab--holder {
                border-bottom: unset;
                margin-left: 9.5rem;
                margin-top: 3rem;
            }
        }
    }
    

    .manage-recs {
        margin-left: 8.5rem;
        padding: 2rem;

        .no-data--card {
            margin-bottom: 100px;
        }

        .last-update-upload{
            display: flex;
            justify-content: space-between;
            padding-right: 15px;
            padding-left: 5px;
            padding-top: 10px;

            .button-container-upload{
                margin-top: -10px;

                svg.upload {
                    border-radius: 40px;
                    margin-left:5px;
                    margin-right: -5px;
                    padding: 4px;
                }
            }
            
            .checkbox-container{
                font-size: 1rem;
                display: flex;
                margin-bottom: 2rem;

                input.drafts-check-box {
                    margin-right: 5px;
                    height: 20px;
                    width: 20px;
                }
            }
        }
            
        .recommended-last-updated {
            width: fit-content;
            position: relative;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 21px;
            color: $gray-neutral-7;
            margin-bottom: 2rem;
            
            &.red {
                color: $red;
            }
        }
    }

    @media all and (max-width: $bp-sidebar-top) {
        margin-left: -9rem; 
    }
}
