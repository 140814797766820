@import "../../assets/scss/variable";

.text-editor-main {
    border: 1px solid $gray-neutral-4;
    border-radius: 4px;
    cursor: text;
    font-size: 16px;
}
  
.text-editor-main .public-DraftEditor-content {
    min-height: 54px;
    margin: 1rem 1rem -1rem 1rem;
    white-space: nowrap;
    overflow: scroll;
}
  
.text-editor-hidePlaceholder .public-DraftEditorPlaceholder-root {
    display: none;
}
  
.text-editor-main .public-DraftStyleDefault-pre {
    background-color: rgba(0, 0, 0, 0.05);
    font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
    font-size: 16px;
}

.toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border-radius: 4px;
    background-color: $purple-light;
    color: $gray-neutral-7;
    font-style: italic;
}
  
.text-editor-styleButton {
    // color: #999;
    cursor: pointer;
    margin-right: 16px;
    padding: 2px 0;
    display: inline-block;
}

.text-editor-styleButton:hover {
    svg {
        path {
            stroke: $blue;
        }
    }
}
  
.text-editor-activeButton {
    svg {
        path {
            stroke: $blue;
        }
    }
}
