@import "../../assets/scss/variable";

.form {
    &--header {
        align-items: center;
        background-color: $green-1;
        color: $white;
        display: flex;
        font-size: 1.1rem;
        height: 3.1rem;
        line-height: 1.3rem;
        padding-left: 1.4rem;
        width: 788px;
    }
    .form--header--secondary {
        align-items: center;
        background-color: $green-1;
        color: $white;
        display: flex;
        font-size: 1.1rem;
        height: 3.1rem;
        line-height: 1.3rem;
        margin-bottom: 3.1rem;
        margin-left: -2rem;
        padding-left: 1.5rem;
        width: 788px;
    }
    form {
        display: inline-grid;
        margin: 1.5rem 1.5rem 1.5rem 1.5rem;
        label {
            color: $gray-neutral-7;
            display: flex;
            font-size: 1.1rem;
            font-weight: 400;
            line-height: 1.3rem;
            margin-bottom: 0.5rem;
            word-wrap: break-word;

            @media (max-width: calc($bp-sidebar-hamburger + 1px)) {
                width: 95vw;
            }

            img {
                padding-left: 0.5rem;
                padding-bottom: 0.2rem;
                padding-right: 0.5rem;
            }
            &.label-long {
                font-size: 1.1rem;
                font-weight: 400;
                line-height: 1.8rem;
                padding: 0 6.6rem 0.8rem 0;
                @media screen and (min-width: 1920px) {
                    padding: 0 6.6rem 0.8rem 0;
                }
                @media screen and (min-width: 2560px) {
                    padding: 0 7rem 0.8rem 0;
                }
                &.question,
                &.question-2,
                &.question-3,
                &.file {
                    img {
                        position: relative;
                    }
                }
                &.file {
                    img {
                        right: 34.5rem;
                        top: 0.9rem;
                        @media screen and (max-width: 1280px) {
                            right: 34.5rem;
                        }
                        @media screen and (min-width: 2560px) {
                            right: 34rem;
                        }
                    }
                }
            }
            &.btn--rounded-outline-gray {
                padding: 1rem 0 0 0.5rem;
                margin-top: 0.1rem;
                width: 209px;
                cursor: pointer;
                svg {
                    padding: 0 0.4rem;
                }
            }
        }
        .container-dia-form {
            background: $white;
            border-radius: 4px;
            border: 2px dashed $gray-neutral-3;
            box-sizing: border-box;
            height: 338px;
            margin: 0 0 3rem;
            padding: 1rem;
            width: 682px;
            &--inputs {
                display: inline-flex;
                input[type="text"] {
                    width: 295px;
                }
                select {
                    width: 294px;
                }
            }
            textarea {
                width: 650px !important;
            }
        }
        .file-attachement {
            background: transparent;
            border-radius: 4px;
            border: 1px solid $gray-neutral-3;
            box-sizing: border-box;
            height: 54px;
            width: 625px;
            margin-bottom: 1rem;
            padding: 0.5rem;
            span {
                background: $gray-neutral-2;
                border-radius: 4px;
                color: $black-2;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.3rem;
                padding: 0.6rem 5rem 0.6rem 2rem;
                position: relative;
                top: 4px;
                width: 172px;
                svg {
                    padding-right: 0.2rem;
                    position: relative;
                    top: 0.2rem;
                }
            }
        }
        .formInput {
            .error-message {
                color: $red;
                display: flex;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.3rem;
                margin-top: -2.5rem;
                margin-bottom: 3.1rem;
            }
            label {
                &.error-label {
                    color: $red;
                }
            }
            input:invalid[focused="true"] {
                border: 1px solid $red;
                color: $black-2;
            }
        }
        .react-datepicker__input-container {
            input {
                width: 335px;
                background: url(../../assets/icons/calendar.svg) no-repeat 36.5rem;

                @media (max-width: calc($bp-sidebar-hamburger + 1px)) {
                    background-position: calc(100% - 15px);
                }
            }
        }
        .input--radio {
            display: inline-flex;
            input[type="radio"] {
                background: $white;
                border-radius: 4px;
                border: 1px solid $gray-neutral-3;
                box-sizing: border-box;
                height: 1.5rem;
                margin-bottom: 0.5rem;
                width: 1.5rem;
                &:checked {
                    color: $blue;
                    border: 2px solid $blue;
                }
            }
            label {
                align-items: center;
                color: $black-2;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.1rem;
                padding-left: 0.3rem;
            }
        }
        input[type="text"] {
            height: 54px;
            margin-right: 1.3rem;
        }
        input[type="text"],
        textarea[type="textarea"] {
            border-radius: 4px;
            border: 1px solid $gray-neutral-4;
            box-sizing: border-box;
            margin-bottom: 3.1rem;
            padding-left: 1rem;
            width: 625px;
            &.input-file {
                display: none;
            }
            &::placeholder {
                color: $gray-neutral-4;
            }

            @media (max-width: calc($bp-sidebar-hamburger + 1px)) {
                width: 85vw;
                display: block;
            }
        }
        .frequently-select {
            select {
                -moz-appearance: none;
                -webkit-appearance: none;
                appearance: none;
                background: transparent url(../../assets/icons/arrow-down.svg) no-repeat 297pxm;
                border-radius: 4px;
                border: 1px solid $gray-neutral-3;
                box-sizing: border-box;
                color: $gray-neutral-3;
                height: 54px;
                margin-bottom: 3.1rem;
                padding-left: 1rem;
                padding: 1rem 1rem 1rem;
                width: 335px;
            }
        }
        textarea {
            height: 115px;
            padding-top: 1rem;
        }
        .mt-6 {
            margin-top: 6rem;
        }
        .mb-3 {
            margin-bottom: 3rem;
        }
        .mb-1-5 {
            margin-bottom: 1.5rem;
        }
        .mb-6 {
            margin-bottom: 6rem;
        }
    }
    .__react_component_tooltip {
        border-radius: 8px !important;
        border: 1px solid $gray-neutral !important;
        box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.02);
        box-sizing: border-box;
        font-size: 0.87rem !important;
        font-weight: 400 !important;
        line-height: 1.06rem !important;
        text-align: center;
        width: 290px;
    }
}

.checkbox-wrapper {
    margin: 1rem 0;
    label {
        display: inline-flex;
        padding-right: 1rem;
    }

    .checkbox-error-message {
        color: $red;
        display: flex;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.3rem;
    }
}

.react-datepicker-popper {
    transform: translate(24px, 180px) ;
}
.react-datepicker__triangle {
    transform: translate(234px, 0px) !important;
}

.dropbtn {
    color:#999999;
    height: auto;
    font-family: "Effra", sans-serif;
}

.selected {
    font-weight: normal;
    padding-right: 50px;
    color: #333;
}

.dropdown-content-show {
    border-color:#CCCCCC;
    border-style: solid;
    border-width: 1px;
}
