@import "../../assets/scss/variable";

.recommendation-modal {
    margin-top: 40px;
    align-items: center;
    h2 {
        color: $black-2;
        display: flex;
        font-size: 1.5rem;
        font-weight: 400;
        justify-content: space-around;
        line-height: 1.8rem;
        margin: 2rem 3rem 0 3rem;
        text-align: center;
    }
    button {
        font-weight: 500;
        height: 51px;
        width: 335px;
        margin: 1rem 0 2.5rem 0;
    }

    .recommendation-content {
        margin: 3rem 2rem;

        label {
            color: $gray-neutral-7;
            display: flex;
            font-size: 1.1rem;
            font-weight: 400;
            line-height: 1.3rem;
            margin-bottom: 0.5rem;
        }

        .content-error {
            color: $red;
            display: flex;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.3rem;
            margin-top: 0.5rem;
        }
    }

    .recommendation-buttons {
        display: flex;
        justify-content: center;
        margin-top: 1.5rem;

        @media (max-width: calc($bp-sidebar-hamburger + 1px)) {
            display: block;
            text-align: center;
        }

        button {
            font-weight: 500;
            height: 51px;
            width: 270px;
            margin: 1rem 1rem 0 1rem;
        }

        .modal-spinner {
            margin-top: 2rem;
        }
    }

    .product-dropdown {
        display: flex;
        flex-direction: column;
        margin-top: 3rem;

        .modal-spinner {
            position: relative;
            margin: 1rem;
            top: -6rem;
            left: 22rem;
        }
    }
}