@import "../../assets/scss/variable";

.drawer-container {
    background: rgba(0, 0, 0, 0.2);
    transition: background-color 1s ease-in;
    align-items: flex-end;
    bottom: 0;
    top: 96px;
    height: 100vh;
    justify-content: center;
    display: flex;
    justify-content: center;
    position: fixed;
    width: 100vw;
    z-index: 1;

    @media (max-width: calc($bp-drawer-content-vertical + 1px)) {
        z-index: 20;
    }

    .drawer-spinner {
        margin-top: 35vh;
        margin-left: 22vw;

        @media (max-width: calc($bp-drawer-content-vertical + 1px)) {
            margin-left: 40vw;
        }
    }

    .drawer {
        width: 50vw;
        right: 0;
        height: 100%;
        position: absolute;
        background-color: $white;
        margin-bottom: 6rem;
        height: calc(100vh - 96px);
        box-shadow: 0px 4px 20px rgba(10, 56, 217, 0.02);

        @media (max-width: calc($bp-drawer-content-vertical + 1px)) {
            width: 100vw;
            border-top: 4px solid $blue-light;
        }

        @media (min-width: calc($bp-drawer-content-vertical + 1px)) and (max-width: $bp-sidebar-top) {
            top: 3rem;
            height: calc(100vh - 9rem);
        }

        .close {
            background-color: unset;
            border-radius: 5px;
            border: none;
            color: $black-2;
            cursor: pointer;
            height: 30px;
            position: absolute;
            right: 1rem;
            top: 2rem;
            transition: 0.3s ease all;
            width: 30px;
            z-index: 20;

            &:hover {
                color: #313030;
            }
        }

        .drawer-content {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            position: absolute;
            top: 0;
            bottom: 0;
            overflow-y: scroll;
            width: 100%;

            &--title {
                color: $black-2;
                font-size: 1.5rem;
                font-weight: 500;
                overflow: hidden;
                position: relative;
                overflow-wrap: break-word;
            }

            &--block {
                display: inline-flex;
                margin: 2rem 0 0 2rem;
                width: calc(100% - 5rem);
                align-items: center;

                img {
                    height: 40px;
                    width: 40px;
                }

                .favorite {
                    margin: 5px 10px 0 15px;
                }
            }

            hr {
                margin: 0 1rem;
                background-color: $gray-neutral-4;
            }

            .details-drawer-accordion {
                .accordion-item {
                    margin: 1.5rem 2rem;
                    box-shadow: 0px 2px 20px rgba(10, 56, 217, 0.1);
                    border: 1px solid $gray-neutral;
                    border-radius: 8px;

                    &.orange {
                        border-left: 8px solid $orange-light-30;
                    }
                    &.blue {
                        border-left: 8px solid $blue-light-30;
                    }
                
                    &.purple {
                        border-left: 8px solid $purple-30;
                    }
                }
            }

            .owners-grid {
                display: flex;
            }

            .category-grid {
                display: grid;
                grid-template-columns: repeat(2, minmax(100px, 300px));
            }

            section {
                margin: 1.5rem 0 0 1.3rem;
                padding-bottom: 1.5rem;

                .container-element {
                    margin: 1rem 6rem 0 0;

                    @media all and (max-width: $bp-sidebar-small) {
                        margin: 1rem 2rem 0 0;
                    }

                    p {
                        color: $gray-neutral-7;
                        font-family: Effra;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        letter-spacing: 0.02em;
                        line-height: 14px;
                        text-transform: uppercase;
                    }

                    .ul-column-set {
                        columns: 2;
                    }

                    ul {
                        line-height: 1.5rem;
                        list-style: none;
                        padding-top: 0.3rem;
                        text-align: left;
                        list-style-type: none;

                        li {
                            font-family: "Effra";
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 1.4rem;
                            color: $black-2;

                            .role-name {
                                font-size: 12px;
                                color: $gray-neutral-7;
                            }
                        }
                    }

                    .report-type {
                        display: inline-flex;
                        padding-top: 0.3rem;
                        align-items: center;
    
                        svg {
                            margin-right: 0.3rem;
                            height: 15px;
                            width: 15px;
                        }
    
                        p {
                            color: $black-2;
                            font-family: Effra;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 17px;
                            text-transform: none;
                        }
                    }
                }
            }

            &--card {
                align-items: center;
                border-radius: 8px;
                border: 1px solid $gray-neutral;
                box-shadow: 0px 4px 20px rgba(10, 56, 217, 0.02);
                box-sizing: border-box;
                position: relative;
                margin: 1rem 2rem;
                text-align: center;
                cursor: pointer;
                box-shadow: 0px 2px 20px rgba(10, 56, 217, 0.1);

                span {
                    display: inline-flex;
                    margin: 1.7rem 1rem;
                    white-space: pre-wrap;
                    align-items: center;
                    color: $black-2;
                    font-size: 16px;
                    font-weight: 500;

                    &.access-request{
                        @media all and (max-width: $bp-sidebar-hamburger) {
                            font-size:13px;
                        }
                    }

                    .text-underline {
                        border-bottom: 1px solid $blue;
                    }

                    img {
                        margin-left: 0.3rem;
                    }

                    &.subtitle {
                        margin-top: unset;
                        font-weight: unset;

                        @media all and (max-width: $bp-sidebar-hamburger) {
                            font-size:12px;
                        }

                        @media all and (max-width: $bp-sidebar-small) and (min-width: $bp-sidebar-hamburger) {
                            font-size:15px;
                        }
                    }
                }

                &.orange {
                    border-top: 8px solid $orange-light-30;
                }
                &.blue {
                    border-top: 8px solid $blue-light-30;
                }
            
                &.purple {
                    border-top: 8px solid $purple-30;
                }
            }
        }
    }
}