@import "../../assets/scss/variable";

.launch-model {
    align-items: center;
    border-radius: 8px;
    background: $white;
    border: 1px solid $gray-neutral;
    box-shadow: 0px 2px 20px rgba(10, 56, 217, 0.1);
    box-sizing: border-box;
    position: relative;
    text-align: center;
    width: 351px;
    background: url('../../assets/images/launch-model-background.png');
    background-size: 115% 143%;
    background-position: top -20px right -23px;
    background-repeat: no-repeat;
    padding: 24px;
    gap: 24px;
    display: flex;
    flex-direction: column;

    &.status{
        background-position: top -48px right -23px;
    }

    @media all and (max-width: calc($bp-sidebar-top)){
        display: none
    }

    @media all and (min-width: calc($bp-sidebar-hamburger)) and (max-width: calc($bp-detail-page-card-small)){
        background-position: bottom -23px right -57px;
    }

    @media all and (min-width: 550px) and (max-width: calc($bp-sidebar-hamburger)){
        background-position: bottom -23px right -40px;
    }

    button {
        padding: 1rem;
    }
    .status-bar{
        padding: 24px;
        align-self: stretch;
        border-radius: 8px;
        box-shadow: 0px 2px 20px 0px rgba(48, 99, 197, 0.10);

        &.Failed{
            border: 1px solid var(--primary-error, $red-1);
            background: var(--10-red, #F8E7E9);
        }

        &.Success{
            border: 1px solid var(--primary-success, #006D3E);
            background: var(--10-green, #E6F0EC);
        }

        &.Validation{
            border: 1px solid var(--primary-sea, $green-1);
            background: var(--10-sea, $green-10);
        }

        .status-header{
            display: flex;

            label{
                gap: 8px;
                display: flex;
                align-items: center;
            }

            img{
                height: 20px;
                width: 20px;
            }
            span{
                color: var(--primary-03, $gray-neutral-7);
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                letter-spacing: 0.24px;
                text-transform: uppercase;
            }
        }

        .status-content{
            text-align: start;
            margin-top: 0.3rem;
            
                .status-update{
                    color: var(--primary-02, $black-2);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 180%;
                }
    
                .status-info{
                    color: var(--primary-03, $gray-neutral-7);
                    font-size: 16px;
                    font-weight: 400;
                }
              
        }
        .form-download-btn{
            .btn--rounded{
                width: 100%;
                padding: 1rem 0.5rem;
            }
        }
    }

}
