@import "../../assets/scss/variable";

.tutorial-modal {
    display: flex;
    height: 500px;
    button {
        font-weight: 500;
        height: 50px;
        width: 110px;
        svg.next-arrow {
            height: 25px;
            width: 25px;
            background-color: $blue-light-30;
            border-radius: 40px;
            margin-left:15px;
            margin-right: -15px;
        }
    } 
}


.video-container {
    float: left;
    height: 100%;
    display: flex;
    align-items: center; /* vertical */
    justify-content: center; /* horizontal */
    overflow: hidden;
    video {  
        height: 100%; 
    }  
    video.tablet {
        visibility: hidden;
        display: none;
    }
    video.desktop {
        visibility: visible;
    }

    .loading-spinner {
        display: flex;
        margin: 0;
        align-items: center; /* vertical */
        justify-content: center; /* horizontal */
        position: absolute;
        left: 700px;
    }
}



.tutorial--description {
    float: left;
    width: 35%;
    margin-top: 70px;
    padding-left: 30px;
    padding-top: 40px;
    padding-bottom: -40px;
    .tutorial-close-button{
        .close {
            background-color: unset;
            border-radius: 5px;
            color: $black-2;
            cursor: pointer;
            height: 30px;
            position: absolute;
            right: 1rem;
            top: 1rem;
            transition: 0.3s ease all;
            width: 30px;
            z-index: 20;

            &:hover {
                color: #313030;
            }
        }
    }
    .description--detail {
        height: 60%;
        width: 80%;
        p.title {
            font-weight: bold;
            font-size: 18px;
            color: $black;
            padding: 5px;
        }
        p.subtitle {
            font-weight: 400;
            font-size: 18px;
            padding: 5px;
            color: $gray-neutral-7;
        }
    }
    
}

.next-button--carousel {
    overflow: visible;
    margin: 20px;
    display: flex;
    justify-content: center; /* horizontal */
}

.carousel {
    position: absolute; 
    left: 30px;
    top:350px;
    display: flex;
    .circle {
        padding: 10px;
    }
    .rectangle {
        padding: 10px;
    }
}

.next-button {
    position: absolute;
    left: 200px;
    top: 343px;
}

.tutorial-modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 22;
    background: rgba(0, 0, 0, 0.2);
}

.tutorial-modal-main {
    position: fixed;
    background-color: $white;
    width: 1120px;
    height: 500px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: visible;
    border-radius: 5px;

}

/* Hide scrollbar for Chrome, Safari and Opera */
.tutorial-modal-main::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.tutorial-modal-main {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.show {
    display: block;
}

.hide {
    display: none;
}


.next-slide {
    position: absolute;
    left: 1100px;
    top: 230px;
    z-index: 1;
}
.prev-slide {
    position: absolute;
    left: -20px;
    top: 230px;

}





@media all and (max-width: $bp-sidebar-top) {
    .tutorial--description, .video-container {
        display: block;
        width: 100%;
    }

    .tutorial--description{
        margin-top: 30px;
        padding-left: 30px;
        padding-top: 10px;
        padding-bottom: 70px;
    }
    .tutorial-modal-main {
        width: 551px;
        height: 735px;
    }


    .tutorial-modal{
        height: 735px;
        flex-direction: column-reverse;

        .video-container {
            float: left;
            transition-delay: 3s;
            height: 80%;
            display: flex;
            align-items: center; /* vertical */
            justify-content: center; /* horizontal */
            overflow: hidden;
            video {  
                height: 100%;  
            }  
            video.tablet {
                visibility: visible;
                display:block;
            }
            video.desktop {
                visibility: hidden;
                display: none;
            }
        }
    }

    .next-button {
        left: 380px;
        top: 650px;
    }

    .carousel { 
        left: 30px;
        top:660px;
    }

    .next-slide {
        left: 530px;
        top: 350px;
    }

    .prev-slide {
        left: -20px;
        top: 350px;
    }

}


