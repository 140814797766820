@import "../../assets/scss/variable";

.modal-header {
    align-items: center;
    border-bottom: 1px solid #e8e8e8;
    display: flex;
    justify-content: space-between;
    padding: 1.1rem 0 1.1rem 1.3rem;
    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.8rem;
    color: $black-2;
}

.modal-form-content {
    overflow-y: initial;
    margin-top: 4rem;
    position: absolute;
    top: 0em;
    bottom: 3em;
    width: 788px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.modal-footer {
    border-radius: 0 0 2px 2px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #fafafa;
    padding: 6px 6px;
    width: 100%;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 7em;
    background: white;
    text-align: center;

    span {
        display: inline-block;
    }
    
    button {
        font-weight: 500;
        height: 51px;
        width: 335px;
        margin-top: 2rem;
    }

    .modal-spinner {
        padding: 3rem 0 0 2rem;
    }
}
